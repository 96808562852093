import { FunctionalComponent, h } from 'preact';
import { PaymentMode, PaymentState } from '../../helpers/use-payments-helper';
import { i18nKeyValue } from '../../i18n/strings/types';
interface Props {
    isMobileDevice: boolean;
    state: FinalState;
    text?: string;
    hideError?: Function;
    setPaymentState?: Function;
    paymentState?: PaymentState | null;
    langStrings: i18nKeyValue;
}
export enum FinalState {
    ERROR = 1,
    PROCESSING = 2,
    SUCCESS = 3,
    TIMER = 4,
    CANCELLED = 5,
}
export const FinalScreen: FunctionalComponent<Props> = (props: Props) => {
    const {
        isMobileDevice,
        langStrings,
        state,
        text,
        hideError,
        paymentState,
        setPaymentState,
    } = props;
    const retryClick = () => {
        hideError && hideError();
    };
    const hideProcessing = () => {
        setPaymentState && setPaymentState(null);
    };
    return (
        <div>
            <div className="processing">
                <div class="page-content">
                    <div className="content">
                        <div className={`image ${state == FinalState.PROCESSING && 'padding-b-0'}`}>
                            <div>
                                {state == FinalState.PROCESSING && (
                                    <div className="align-center">
                                        <div className="loading"></div>
                                    </div>
                                )}
                                {(state == FinalState.ERROR || state == FinalState.CANCELLED) && (
                                    <img src="/assets/images/error.svg" alt="" />
                                )}
                                {state == FinalState.SUCCESS && (
                                    <div className="success-payment">
                                        <a
                                            href="#"
                                            class="ripple-container colorBg"
                                            target="_blank"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                id="Capa_1"
                                                x="0px"
                                                y="0px"
                                                width="45px"
                                                height="45px"
                                                viewBox="0 0 78.369 78.369"
                                            >
                                                <g>
                                                    <path
                                                        fill="white"
                                                        d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704   c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704   C78.477,17.894,78.477,18.586,78.049,19.015z"
                                                    />
                                                </g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                            </svg>
                                            <span class="ripple colorBg"></span>
                                            <span class="ripple colorBg"></span>
                                            <span class="ripple colorBg"></span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        {state == FinalState.PROCESSING && (
                            <div className="info">
                                <h3>{langStrings.webview_paymentprocessing_content}</h3>
                                {paymentState && paymentState.mode == PaymentMode.UPI && (
                                    <div>
                                        <p>{langStrings.webview_paymentprocessing_retrymsg}</p>
                                        <button onClick={hideProcessing}>
                                            {langStrings.webview_paymentprocessing_retrycta}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {state == FinalState.SUCCESS && (
                            <div className="info">
                                <h3>{langStrings.payment_successful}</h3>
                            </div>
                        )}
                        {state == FinalState.ERROR && (
                            <div className="info">
                                <h3>{langStrings.webview_pd_failed}</h3>
                                <p>{text}</p>
                                <button onClick={retryClick}>{langStrings.try_again}</button>
                            </div>
                        )}
                        {state == FinalState.CANCELLED && (
                            <div className="info">
                                <h3>{langStrings.webview_pd_failed}</h3>
                                <p>{text ? text : langStrings.webview_pd_failed_msg}</p>
                            </div>
                        )}
                        {state == FinalState.PROCESSING &&
                            paymentState &&
                            paymentState.mode == PaymentMode.UPI && (
                                <div class="processing-footer-text-wrapper">
                                    <div class="processing-footer-text">
                                        {langStrings.webview_paymentprocessing_refundmsg}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};
