import { FunctionalComponent, h } from 'preact';
import { i18nKeyValue } from '../../i18n/strings/types';
import { getUserAgentInfo } from '../../utils/get-user-agent';
interface Props {
    langStrings: i18nKeyValue;
}
export const NoInternet: FunctionalComponent<Props> = (props: Props) => {
    const { langStrings } = props;
    function reload() {
        window.location.reload();
    }
    return (
        <div className="no-internet">
            <div class="overlay"></div>
            <div className="container">
                <div className="image">
                    <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                            fill="#FFADB9"
                        />
                        <path
                            d="M54.3315 70.9414L49.6453 75.6413L44.9386 70.9414C45.555 70.3241 46.2871 69.8344 47.093 69.5003C47.8988 69.1661 48.7627 68.9941 49.6351 68.9941C50.5074 68.9941 51.3713 69.1661 52.1771 69.5003C52.983 69.8344 53.7151 70.3241 54.3315 70.9414Z"
                            fill="white"
                        />
                        <path
                            d="M63.7722 61.4461L59.277 65.9618C57.6445 64.3329 55.6257 63.1445 53.4093 62.5075C51.1929 61.8704 48.8512 61.8057 46.603 62.3192L53.0287 55.8936C57.0979 56.5888 60.8514 58.5287 63.7722 61.4461Z"
                            fill="white"
                        />
                        <path
                            d="M72.9195 52.265L68.5675 56.617C65.7468 53.8086 62.3352 51.6647 58.5812 50.3414L63.3151 45.6074C66.8894 47.2337 70.1424 49.4887 72.9195 52.265Z"
                            fill="white"
                        />
                        <path
                            d="M82.3602 42.7827L77.8172 47.3461C75.0622 44.6001 71.9236 42.2679 68.4993 40.4225L73.2128 35.709C76.5451 37.6761 79.6181 40.0525 82.3602 42.7827Z"
                            fill="white"
                        />
                        <path
                            d="M77.1624 26.8553L54.7203 49.2973L48.3697 55.6479L38.9905 65.0271L30.6958 73.3218L28.3356 70.9412L36.6235 62.667L35.4707 61.5074C37.4101 59.5528 39.7324 58.0201 42.292 57.0053L50.4775 48.8198H49.659C46.1247 48.8128 42.6241 49.5068 39.3597 50.8614C36.0954 52.2161 33.1321 54.2047 30.6412 56.712L26.2688 52.36C30.0984 48.5147 34.8172 45.6732 40.0072 44.0872C45.1972 42.5012 50.6983 42.2195 56.0232 43.2673L61.719 37.5647C54.7297 35.3527 47.2667 35.1061 40.1466 36.8518C33.0266 38.5975 26.524 42.2683 21.3506 47.4624L16.794 42.9262C23.1931 36.4989 31.3361 32.0886 40.2155 30.241C49.095 28.3933 58.3213 29.1893 66.7531 32.5306L74.7954 24.4883L77.1624 26.8553Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <p>{langStrings.no_internet_connection}</p>
                <button onClick={reload}>{langStrings.try_again}</button>
            </div>
        </div>
    );
};
