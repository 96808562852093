import { FunctionalComponent, h } from 'preact';
import { PaymentMode, usePaymentHelper } from '../helpers/use-payments-helper';
import { useUtilsHelper } from '../helpers/use-utils-helper';
import {NetBankingItem, OrderStatus, PaymentOptions, Tenant} from '../services/payments';
import {
    Header,
    CardPayment,
    UPIPayment,
    WalletPayment,
    PaymentInfo,
    FinalScreen,
    NoInternet,
} from './';
import { FinalState } from './final-screen';
import { QREnlarge } from './upi-payment/qr-enlarge';
import {ControlWrapper} from "./control-wrapper";
import {NetBanking} from "./net-banking";

const App: FunctionalComponent = () => {
    const {
        tenant,
        theme,
        paymentMode,
        updatePaymentMode,
        paymentOrderDetail,
        makeUpiPayment,
        makeCardPayment,
        makeWalletPayment,
        handleBackClick,
        isAppLoading,
        upiIntentOrder,
        setShouldPoll,
        getSurchargeForCardOrWallet,
        isCreatingCardOrder,
        isCreatingWalletOrder,
        isCreatingUpiOrder,
        paymentError,
        displayError,
        hideError,
        paymentState,
        setPaymentState,
        completeKYCClick,
        contactUsClick,
        netBankingSupportedBankList,
        initiateNetBanking
    } = usePaymentHelper();
    const {
        isOffline,
        langStrings,
        isMobileDevice,
        isQREnlarged,
        enlargeQRImage,
        closeQRImage,
    } = useUtilsHelper();
    const isKbInvest= tenant === Tenant.KB_INVEST
    const isCCEligibleForPayment = paymentOrderDetail?.paymentOptions.includes(PaymentOptions.CC)
    const selectUpi = () => {
        updatePaymentMode(PaymentMode.UPI);
    };
    const selectCard = () => {
        if (
            paymentOrderDetail!.paymentOptions.includes(PaymentOptions.CC) ||
            paymentOrderDetail!.paymentOptions.includes(PaymentOptions.DC)
        ) {
            updatePaymentMode(PaymentMode.CARD);
        }
    };
    const selectWallet = () => {
        if (paymentOrderDetail!.paymentOptions.includes(PaymentOptions.WALLET)) {
            updatePaymentMode(PaymentMode.WALLET);
        }
    };
    const hasUpiIntent = Array.isArray(upiIntentOrder?.intents)
        ? !!upiIntentOrder?.intents?.find((i) => i.type === 'upi')
        : false;


    let isb2bEnabled = false
    if (paymentOrderDetail) {
        isb2bEnabled = (tenant == 'b2b' && !paymentOrderDetail?.isKYCDone) ||
            (tenant == 'b2b' &&
                paymentOrderDetail?.isKYCDone &&
                paymentOrderDetail?.limitBucketId == 8)
    }

    const selectNetBanking = () => {
        if (
            paymentOrderDetail!.paymentOptions.includes(PaymentOptions.NET_BANKING)
        ) {
            updatePaymentMode(PaymentMode.NET_BANKING);
        }
    }
    const isNetBankingEnabled = paymentOrderDetail?.paymentOptions.includes(PaymentOptions.NET_BANKING)
    return (
        <div
            className={
                theme +
                ' app-container ' +
                (isMobileDevice ? 'mobile' : 'desktop') +
                (isAppLoading ? ' app-container-loading' : '') +
                (paymentOrderDetail &&
                isMobileDevice &&
                paymentOrderDetail.status === OrderStatus.SUCCESS
                    ? ' mobile-app-payment-success'
                    : '')
            }
            id="app"
        >
            {isQREnlarged && (
                <QREnlarge closeQRImage={closeQRImage} upiIntentOrder={upiIntentOrder} />
            )}

            {paymentOrderDetail &&
                !(isMobileDevice && paymentOrderDetail.status === OrderStatus.SUCCESS) && (
                    <Header
                        tenant={tenant}
                        handleBackClick={handleBackClick}
                        paymentState={paymentState}
                        langStrings={langStrings}
                        isMobileDevice={isMobileDevice}
                    />
                )}
            {isAppLoading ||
                (isCreatingUpiOrder &&
                    paymentOrderDetail &&
                    paymentOrderDetail.status == OrderStatus.PENDING && (
                        <div class="loading loading-center"></div>
                    ))}
            {!isAppLoading &&
                paymentOrderDetail &&
                paymentOrderDetail.status !== OrderStatus.SUCCESS &&
                (paymentError || paymentState?.processing) &&
                isMobileDevice && (
                    <div className="container">
                        <div className="payment-info-container">
                            <PaymentInfo
                                handleBackClick={handleBackClick}
                                isMobileDevice={isMobileDevice}
                                langStrings={langStrings}
                                amount={(paymentOrderDetail.amount / 100).toFixed(2)}
                            />
                        </div>
                    </div>
                )}
            {paymentError && displayError && (
                <FinalScreen
                    isMobileDevice={isMobileDevice}
                    state={FinalState.ERROR}
                    langStrings={langStrings}
                    hideError={hideError}
                    text={paymentError}
                />
            )}
            {/* TODO: Show Loader */}
            {!isAppLoading &&
                paymentOrderDetail &&
                paymentOrderDetail.status === OrderStatus.SUCCESS && (
                    <FinalScreen
                        langStrings={langStrings}
                        state={FinalState.SUCCESS}
                        isMobileDevice={isMobileDevice}
                    />
                )}
            {!isAppLoading && paymentState?.processing && (
                <FinalScreen
                    langStrings={langStrings}
                    state={FinalState.PROCESSING}
                    paymentState={paymentState}
                    setPaymentState={setPaymentState}
                    isMobileDevice={isMobileDevice}
                />
            )}
            {!isAppLoading &&
                paymentOrderDetail &&
                paymentOrderDetail.status == OrderStatus.CANCELLED && (
                    <FinalScreen
                        langStrings={langStrings}
                        state={FinalState.CANCELLED}
                        isMobileDevice={isMobileDevice}
                    />
                )}
            {!isAppLoading &&
                paymentOrderDetail &&
                paymentOrderDetail.status !== OrderStatus.CANCELLED &&
                paymentOrderDetail.status !== OrderStatus.SUCCESS &&
                !paymentState?.processing &&
                !(paymentError && isMobileDevice) && (
                    <div className="container">
                        <PaymentInfo
                            langStrings={langStrings}
                            handleBackClick={handleBackClick}
                            isMobileDevice={isMobileDevice}
                            amount={(paymentOrderDetail.amount / 100).toFixed(2)}
                        />
                        <div className="modes-title">
                            <p>{langStrings.webview_paymode}</p>
                        </div>
                        {(paymentOrderDetail.paymentOptions.includes(PaymentOptions.UPI) ||
                            paymentOrderDetail.paymentOptions.includes(
                                PaymentOptions.UPI_INTENT
                            )) && hasUpiIntent && (
                            <div className="card upi">
                                <div onClick={selectUpi} className="card-header">
                                    <div className="left">
                                        <div className="title-img">
                                            <img src="/assets/images/upi.svg" alt="" />
                                        </div>
                                        <div className="title-promotion">
                                            {langStrings.webview_upi}
                                            <span className="free">{langStrings.webview_free}</span>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img
                                            src={
                                                '/assets/images/' +
                                                (paymentMode == PaymentMode.UPI
                                                    ? 'selected'
                                                    : 'unselected') +
                                                '.svg'
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                                {paymentMode == PaymentMode.UPI && (
                                    <UPIPayment
                                        langStrings={langStrings}
                                        setPaymentState={setPaymentState}
                                        isMobileDevice={isMobileDevice}
                                        makeUpiPayment={makeUpiPayment}
                                        upiIntentOrder={upiIntentOrder}
                                        setShouldPoll={setShouldPoll}
                                        enlargeQrCode={enlargeQRImage}
                                        isCreatingUpiOrder={isCreatingUpiOrder}
                                        paymentOrderDetail={paymentOrderDetail}
                                        tenant={tenant}
                                    />
                                )}
                            </div>
                        )}
                        {!paymentOrderDetail.isKYCDone &&
                            tenant == 'b2b' &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.CC) &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.DC) &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.WALLET) && (
                                <div className={`card no-kyc-card`}>
                                    <div className="card-body">
                                        <div className="info-container">
                                            <div className="left">
                                                <div className="title">
                                                    {langStrings.paymentssdk_kyc_title}
                                                </div>
                                                <div className="sub-title">
                                                    {langStrings.paymentssdk_kyc_content}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img src="/assets/images/kyc.svg" alt="kyc" />
                                            </div>
                                        </div>
                                        <button onClick={() => completeKYCClick()}>
                                            {langStrings.paymentssdk_kyc_cta}
                                        </button>
                                    </div>
                                </div>
                            )}
                        {paymentOrderDetail.isKYCDone &&
                            tenant == 'b2b' &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.CC) &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.DC) &&
                            !paymentOrderDetail.paymentOptions.includes(PaymentOptions.WALLET) &&
                            paymentOrderDetail.limits.CC.kyc_limit > 0 && (
                                <div className="card kyc-limit-card">
                                    <div className="card-body">
                                        <div className="left">
                                            <img src="/assets/images/warning.svg" alt="kyc" />
                                        </div>
                                        <div className="right">
                                            <div className="title">
                                                {langStrings.paymentssdk_limitexceeded.replace(
                                                    '%(amount)s',
                                                    paymentOrderDetail.limits.CC.kyc_limit.toString()
                                                )}
                                            </div>
                                            <button onClick={() => contactUsClick()}>
                                                {langStrings.paymentssdk_supportcta}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {paymentOrderDetail &&
                            (paymentOrderDetail.paymentOptions.includes(PaymentOptions.DC) ||
                                paymentOrderDetail.paymentOptions.includes(PaymentOptions.CC) ||
                                (tenant == 'b2b' && !paymentOrderDetail.isKYCDone) ||
                                (tenant == 'b2b' &&
                                    paymentOrderDetail.isKYCDone &&
                                    paymentOrderDetail.limitBucketId == 8)) && (
                                <div className="card card-pay">
                                    <div
                                        onClick={selectCard}
                                        className={`card-header ${
                                            !(
                                                paymentOrderDetail.paymentOptions.includes(
                                                    PaymentOptions.DC
                                                ) ||
                                                paymentOrderDetail.paymentOptions.includes(
                                                    PaymentOptions.CC
                                                )
                                            )
                                                ? paymentOrderDetail.isKYCDone
                                                    ? 'upgrade-limit no-kyc'
                                                    : 'no-kyc'
                                                : ''
                                        }`}
                                    >
                                        <div className="left">
                                            <div className="title-img">
                                                {paymentOrderDetail.paymentOptions.includes(
                                                    PaymentOptions.DC
                                                ) ||
                                                paymentOrderDetail.paymentOptions.includes(
                                                    PaymentOptions.CC
                                                ) ? (
                                                    <img src="/assets/images/card.svg" alt="" />
                                                ) : (
                                                    <img src="/assets/images/lock.svg" alt="" />
                                                )}
                                            </div>
                                            <div className="title-container">
                                                <div className="title">
                                                    {isCCEligibleForPayment? langStrings.webview_card: langStrings?.debit_card}
                                                </div>
                                                {!paymentOrderDetail.isKYCDone &&
                                                    tenant == 'b2b' &&
                                                    !paymentOrderDetail.paymentOptions.includes(
                                                        PaymentOptions.DC
                                                    ) &&
                                                    !paymentOrderDetail.paymentOptions.includes(
                                                        PaymentOptions.CC
                                                    ) && (
                                                        <div className="sub-title">
                                                            Charges are applied per transaction.
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img
                                                src={
                                                    '/assets/images/' +
                                                    (paymentMode == PaymentMode.CARD
                                                        ? 'selected'
                                                        : 'unselected') +
                                                    '.svg'
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    {paymentMode == PaymentMode.CARD && (
                                        <CardPayment
                                            payment={paymentOrderDetail}
                                            langStrings={langStrings}
                                            isMobileDevice={isMobileDevice}
                                            makeCardPayment={makeCardPayment}
                                            isCreatingCardOrder={isCreatingCardOrder}
                                            paymentOptions={paymentOrderDetail.paymentOptions}
                                            tenant={tenant as Tenant}
                                        />
                                    )}
                                </div>
                            )}
                        {((paymentOrderDetail &&
                            paymentOrderDetail.paymentOptions.includes(PaymentOptions.WALLET)) ||
                            (tenant == 'b2b' && !paymentOrderDetail.isKYCDone) ||
                            (tenant == 'b2b' &&
                                paymentOrderDetail.isKYCDone &&
                                paymentOrderDetail.limitBucketId == 8)) && (
                            <div className="card wallet">
                                <div
                                    onClick={selectWallet}
                                    className={`card-header ${
                                        paymentMode == PaymentMode.WALLET
                                            ? 'card-header-active'
                                            : ''
                                    } ${
                                        !paymentOrderDetail.paymentOptions.includes(
                                            PaymentOptions.WALLET
                                        )
                                            ? paymentOrderDetail.isKYCDone
                                                ? 'upgrade-limit no-kyc'
                                                : 'no-kyc'
                                            : ''
                                    }`}
                                >
                                    <div className="left">
                                        <div className="title-img">
                                            {paymentOrderDetail.paymentOptions.includes(
                                                PaymentOptions.WALLET
                                            ) ? (
                                                <img src="/assets/images/wallet.svg" alt="" />
                                            ) : (
                                                <img src="/assets/images/lock.svg" alt="" />
                                            )}
                                        </div>
                                        <div className="title-container">
                                            <div className="title">
                                                {langStrings.webview_wallet}
                                            </div>
                                            {!paymentOrderDetail.isKYCDone &&
                                                tenant == 'b2b' &&
                                                !paymentOrderDetail.paymentOptions.includes(
                                                    PaymentOptions.WALLET
                                                ) && (
                                                    <div className="sub-title">
                                                        {langStrings.paymentssdk_kyc_content}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img
                                            src={
                                                '/assets/images/' +
                                                (paymentMode == PaymentMode.WALLET
                                                    ? 'selected'
                                                    : 'unselected') +
                                                '.svg'
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                                {paymentMode == PaymentMode.WALLET && (
                                    <WalletPayment
                                        makeWalletPayment={makeWalletPayment}
                                        langStrings={langStrings}
                                        isCreatingWalletOrder={isCreatingWalletOrder}
                                        paymentOrderDetail={paymentOrderDetail}
                                    />
                                )}
                            </div>
                        )}
                        <ControlWrapper shouldShow={!!paymentOrderDetail && !!isNetBankingEnabled}>
                                    <div className="card card-pay">
                                        <div
                                            onClick={selectNetBanking}
                                            style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:'100%', padding: '10px 16px'}}
                                        >
                                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    <img src="/assets/images/bank.svg" alt="" />
                                                    <p style={{marginLeft: 9}}>
                                                        Net Banking
                                                    </p>
                                            </div>
                                            <div className="right">
                                                <img
                                                    src={
                                                        '/assets/images/' +
                                                        (paymentMode == PaymentMode.NET_BANKING
                                                            ? 'selected'
                                                            : 'unselected') +
                                                        '.svg'
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        {paymentMode == PaymentMode.NET_BANKING && (
                                            <NetBanking
                                                netBankingSupportedBankList={netBankingSupportedBankList}
                                                initiateNetBanking={initiateNetBanking}
                                            />
                                        )}
                                    </div>
                        </ControlWrapper>










                        {isKbInvest && (<div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
                            <img src="/assets/images/lendbox-icon.svg" alt="" />
                        </div>)}
                        {tenant !== Tenant.KB_INVEST &&(<div className="footer">
                            <div className="image">
                                <img src="/assets/images/secure.svg" alt="" />
                            </div>
                            <div className="text">{langStrings.webview_footer}</div>
                        </div>)}
                    </div>
                )}
            {isOffline && <NoInternet langStrings={langStrings} />}
        </div>
    );
};

export default App;
