import {h} from 'preact'
import {useRef, useState} from "preact/hooks";
import {NetBankingItem} from "../../services/payments";
import {ControlWrapper} from "../control-wrapper";


export const NetBanking = (
    {
        netBankingSupportedBankList,
        initiateNetBanking
    }: {
        netBankingSupportedBankList: Array<NetBankingItem>;
        initiateNetBanking: Function;
    }) => {

    const [netBankingList, updateNetBankingList] = useState(netBankingSupportedBankList)
    const inputRef = useRef(null);
    const [selectedNetBankingBank, updateSelectedBank] = useState<null | NetBankingItem>(null)
    const [showNetBankingModal, updateNetBankingModalVisibility] = useState(false);
    const [inputText, updateInputText] = useState('')


    const mostUsedBankList = [
        {
            bankList: [
                {bankIcon: '/assets/images/sbi.svg', bankName: 'SBI', bankCode: 'SBIN'},
                {bankIcon: '/assets/images/hdfc.svg', bankName: 'HDFC', bankCode: 'HDFC'},
                {bankIcon: '/assets/images/icici.svg', bankName: 'ICICI', bankCode: 'ICIC'}
            ]
        },
        {
            bankList: [
                {bankIcon: '/assets/images/axis_bank.svg', bankName: 'Axis', bankCode: 'UTIB'},
                {bankIcon: '/assets/images/kotak_icon.svg', bankName: 'Kotak', bankCode: 'KKBK'},
                {bankIcon: '/assets/images/yes_bank.svg', bankName: 'Yes Bank', bankCode: 'YESB'}
            ]
        }
    ]


    const onInput = (e: any) => {
        // @ts-ignore
        const text = e.target.value
        updateInputText(e.target.value)
        updateNetBankingList(netBankingSupportedBankList.filter((item) => item.bankName.toLowerCase().includes(text.toLowerCase())))
    }

    const onBankSelection = (selectedBank: NetBankingItem) => () => {
        updateSelectedBank(selectedBank)
    }

    const onClickInput = () => {
        document.body.style.overflow = 'hidden'
        updateNetBankingModalVisibility(true)

    }

    const onCloseModal = () => {
        document.body.style.overflow = 'unset'
        updateInputText('')
        updateSelectedBank(null)
        updateNetBankingList(netBankingSupportedBankList)
        updateNetBankingModalVisibility(false)
    }
    const onPrimaryBankSelection = (selectedBank: NetBankingItem) => () => {
        updateSelectedBank(selectedBank)
        initiateNetBanking(selectedBank)
    }

    const onProceed = () => {
        if (selectedNetBankingBank) {
            initiateNetBanking(selectedNetBankingBank)
        }
    }
    return (
        <div className="net-banking">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {mostUsedBankList.map(({bankList}) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 10
                    }}>
                        {bankList.map(({bankName, bankIcon, bankCode}) => {
                            return (
                                <div
                                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                                    onClick={onPrimaryBankSelection({bankCode, bankName})}
                                >
                                    <a style={{
                                        width: 90,
                                        height: 70,
                                        margin: '10px 10px 4px 10px',
                                        borderRadius: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #D0D5DD',
                                    }}>
                                        <img src={bankIcon} alt=""/>
                                    </a>
                                    <div style={{
                                        textAlign: 'center',
                                        flex: 1,
                                        fontSize: 14,
                                        color: '#101828'
                                    }}>{bankName}</div>
                                </div>)
                        })}
                    </div>
                ))}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    border: '1px solid #cfd0d1',
                    borderRadius: 12,
                    padding: 8,
                    alignItems: "center",
                    margin: '10px 0px'
                }}
                     onClick={onClickInput}
                >
                    <p style={{fontSize: 14, margin: 0, color: '#667085'}}>Select a different bank</p>
                    <img src="/assets/images/arrow-down.svg"/>
                </div>
            </div>
            <ControlWrapper shouldShow={showNetBankingModal}>
                <div className="modal">
                    <div className="modal-content">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                            marginBottom: 10
                        }}>
                            <p style={{margin: 0, fontWeight: 500}}>All Banks</p>
                            <img src="/assets/images/cross.svg" alt="Logo" onClick={onCloseModal}/>
                        </div>

                        <input
                            id="input"
                            ref={inputRef}
                            autoComplete="off"
                            list=""
                            name="browsers"
                            placeholder="Select a bank name"
                            onInput={onInput}
                            value={inputText}
                            style={{
                                marginTop: 4,
                                backgroundImage: `url('/assets/images/search.svg')`,
                                backgroundPosition: '10px 9px',
                                backgroundRepeat: 'no-repeat',
                                padding: '12px 20px 12px 35px',
                                backgroundColor: '#F0F2F4',
                                border: 'none'
                            }}
                        />
                        <div className="list-container" id="browsers" role="listbox">
                            <div>
                                {netBankingList.map((bankDetail) => (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        borderBottom: '1px solid #cfd0d1',
                                        padding: '5px 0px'
                                    }}
                                         onClick={onBankSelection(bankDetail)}
                                    >
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <img src="/assets/images/house.svg" alt="Logo"/>
                                            <p style={{marginTop: 0}}>{bankDetail.bankName}</p>
                                        </div>

                                        <img
                                            src={
                                                `/assets/images/${
                                                    selectedNetBankingBank && selectedNetBankingBank.bankName === bankDetail.bankName ?
                                                        'bank-selected'
                                                        : 'bank-unselected'
                                                }.svg`
                                            }/>
                                    </div>

                                ))}
                            </div>
                            <ControlWrapper shouldShow={netBankingList.length === 0 && inputText.length > 0}>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: 'center',
                                    alignContent: 'center'
                                }}> No Result Found
                                </div>
                            </ControlWrapper>
                        </div>
                        <button style={{
                            background: '#0752AD',
                            color: 'white',
                            padding: '16px 14px',
                            margin: '10px 0px',
                            borderRadius: 12,
                            textAlign: 'center'
                        }}
                                disabled={!selectedNetBankingBank}
                                onClick={onProceed}
                        >
                            PROCEED
                        </button>
                    </div>
                </div>
            </ControlWrapper>
        </div>
    )
}