import { useCallback, useEffect, useState } from 'preact/hooks';
import { useAnalyticsEvents } from '../analytics/events.home';
import RazorpayService from '../services/rzp';
import { getUserAgentInfo } from '../utils/get-user-agent';
import { PaymentMode } from './use-payments-helper';
import AndroidBridge, { RequestFunction } from '../utils/android-bridge';
import {PaymentService} from "../services/payments";
import {Utils} from "../utils/utils";

interface IUseUpiInputHelper {
    upiId: string;
    isVerifyingUpiId: boolean;
    isDisabled: boolean;
    UPIApps: UpiApp[];
    upiVerificationError: string | null;
    upiAppClick: Function;
    verifyUpiId: (upiId: string) => Promise<void>;
    useNatveUPIIntent: boolean;
    setUpiId: (upiId: string) => void;
    setIsVerifyingUpiId: (flag: boolean) => void;
    setIsDisabled: (flag: boolean) => void;
}

export interface UpiApp {
    appName: string;
    appIcon: string;
    packageName: string;
    link?: string;
}

enum Client {
    Android = 'Android',
}

const appHandlerMapper: { [key: string]: string } = {
    PhonePay: 'phonepe',
    'Google Pay': 'tez',
    Paytm: 'paytm'
};

declare global {
    interface Window {
        setUPIApps: Function;
        upiApps: UpiApp[];
        Android: any;
    }
}

export const useUpiInputHelper = (upiPaymentUrl: string | undefined, props:any): IUseUpiInputHelper => {
    const analytics = useAnalyticsEvents();
    const userAgent = getUserAgentInfo();
    const paymentService = new PaymentService();

    const apps: UpiApp[] = [
        {
            appIcon: '/assets/images/phonepe.svg',
            appName: 'PhonePay',
            packageName: '',
        },
        {
            appIcon: '/assets/images/gpay.svg',
            appName: 'Google Pay',
            packageName: '',
        },
        {
            appIcon: '/assets/images/paytm.svg',
            appName: 'Paytm',
            packageName: '',
        },
    ];

    const [upiId, setUpiId] = useState('');
    const [isVerifyingUpiId, setIsVerifyingUpiId] = useState(false);
    const [UPIApps, updateUPIApps] = useState<UpiApp[]>([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [useNatveUPIIntent, setUseNatveUPIIntent] = useState(userAgent.os === 'android');
    const [upiVerificationError, setUpiVerificationError] = useState<string | null>(null);
    const rzpService = new RazorpayService();
console.log({UPIApps})
    const verifyUpiId = async (upiId: string) => {
        // TODO: debounce
        if (!(upiId.indexOf('@') >= 0)) return;
        // console.log('verifying upi id', upiId);
        setIsVerifyingUpiId(true);
        setIsDisabled(true);
        const verified = await rzpService.verifyUpiId(upiId);
        setIsVerifyingUpiId(false);
        if (verified) {
            setIsDisabled(false);
        } else {
            setUpiVerificationError('unable to verify');
        }
    };

    useEffect(() => {
        var searchParams = new URLSearchParams(location.search);
        let client = searchParams.get('client');
        console.log(window.Android)
        if (
            window.Android &&
            (window.Android.initialiseSDK || window.Android.handleRequest)
        ) {
            window.setUPIApps = resetUPIApps;
            window.Android?.initialiseSDK?.();
            initialiseSdkWithHandleRequest();
        } else {
            updateUPIApps(apps);
        }
    }, [location.search]);

    useEffect(() => {
        window.setUPIApps = resetUPIApps;
    }, [apps]);
    useEffect(() => {
        if (window.upiApps) {
            updateUPIApps(window.upiApps);
        }
    }, [window.upiApps]);

    const initialiseSdkWithHandleRequest = async () => {
        const res = await AndroidBridge.execute(RequestFunction.initialiseWebPaymentSdk,[window.location.href])
        updateUPIApps(JSON.parse(res?.result))
        setUseNatveUPIIntent(false);
    };

    const resetUPIApps = useCallback(
        (apps: UpiApp[]) => {
            window.upiApps = apps;
            setUseNatveUPIIntent(false);
            updateUPIApps(apps);
        },
        [window.upiApps]
    );

    const getUpiAppUrl = (appName: string) => {
        if (appHandlerMapper[appName]) {
            return upiPaymentUrl?.replace('upi://pay', `${appHandlerMapper[appName]}://upi/pay`);
        } else {
            return undefined;
        }
    };

    const upiAppClick = (app: UpiApp, intentUrl: string) => {
        analytics.makePayment({ mode: PaymentMode.UPI, upiMode: 'intent' });
        if (app) {
            if (props?.paymentOrderDetail?.payer?.phone && (app.packageName || app.appName)) {
                paymentService.syncUsedPspApp({
                    mobileNumber: props?.paymentOrderDetail?.payer?.phone,
                    usedPspApp: {
                        packageName: app?.packageName,
                        appName: app?.appName
                    }
                })
            }
        }

        if (app && app.packageName && !!window.Android) {
            window.Android?.openIntent?.(intentUrl, app.packageName);
            // window.Android?.paymentIntent?.(intentUrl, app.packageName);
            AndroidBridge.execute(RequestFunction.paymentIntent,[intentUrl, app.packageName])
        } else if (app && getUpiAppUrl(app.appName)) {
            if (window?.webkit?.messageHandlers?.openApp) {
                window.webkit.messageHandlers.openApp.postMessage({
                    message: 'openApp',
                    params: {
                        url: getUpiAppUrl(app.appName),
                    },
                });
            } else {
                window.open(getUpiAppUrl(app.appName));
            }
        } else {
            window.open(intentUrl, '_self');
        }
    };

    const pspMapHandling = (lastUsedPspAppName: string) => {
        if (lastUsedPspAppName.toLowerCase().includes('google') || lastUsedPspAppName.toLowerCase().includes('gpay')) {
            return 'Google Pay'
        }
        if (lastUsedPspAppName.toLowerCase().includes('phone')) {
            return 'PhonePay'
        }
        if (lastUsedPspAppName.toLowerCase().includes('paytm')) {
            return 'Paytm'
        }
        return lastUsedPspAppName
    }

    useEffect(() => {
        if(!props.isMobileDevice) return
        const search = window.location.search
        const searchParam = new URLSearchParams(search)
        const lastUsedPsp = searchParam.get('appName')||''
        const lastUsedPspPackageName = searchParam.get('packageName')
        const lastUsedPspAppName: string = pspMapHandling(lastUsedPsp)
        if ((!!lastUsedPspAppName || !!lastUsedPspPackageName) && upiPaymentUrl) {
            props.setShouldPoll(true);
            props.setPaymentState({
                mode: PaymentMode.UPI,
                processing: true,
            });
            upiAppClick({
                appName: lastUsedPspAppName ?? '',
                appIcon: '',
                packageName: lastUsedPspPackageName ?? '',
            }, upiPaymentUrl);
            Utils.clearSearchParamFromURL('appName')
            Utils.clearSearchParamFromURL('packageName')
        }
    }, [])

    return {
        upiId,
        isVerifyingUpiId,
        isDisabled,
        verifyUpiId,
        useNatveUPIIntent,
        UPIApps,
        upiVerificationError,
        upiAppClick,
        setUpiId,
        setIsVerifyingUpiId,
        setIsDisabled,
    };
};
