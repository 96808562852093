import { FunctionalComponent, h } from 'preact';
import { i18nKeyValue } from '../../i18n/strings/types';
import { getUserAgentInfo } from '../../utils/get-user-agent';
interface Props {
    isMobileDevice: boolean;
    amount: string;
    handleBackClick: Function;
    langStrings: i18nKeyValue;
}
export const PaymentInfo: FunctionalComponent<Props> = (props: Props) => {
    const { isMobileDevice, langStrings, handleBackClick } = props;
    function handleArrowClick() {
        handleBackClick();
    }
    return (
        <div className="card payment-info">
            <div className="navigation">
                <div onClick={handleArrowClick} className="arrow">
                    <img src="/assets/images/back-arrow.svg" alt="" />
                </div>
                <div onClick={handleArrowClick} className="text">
                    {langStrings.webview_goback}
                </div>
            </div>
            <div className="card-header amount">
                <div className="left">
                    {' '}
                    <p> {langStrings.webview_amount}</p>
                </div>
                <div className="right">
                    <p>₹{props.amount}</p>{' '}
                </div>
            </div>
        </div>
    );
};
