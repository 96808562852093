import Constants from '../utils/constants';

export interface RzpPgIntent {
    type?: 'pg';
    order_id: string;
    amount: number;
    currency?: string;
    email: string;
    contact?: string;
    notes?: Object;
}

export interface RzpSuccessResponse {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
}

export interface RzpFailureResponse {
    error: {
        code: string;
        description: string;
        metadata: {
            payment_id: string;
            order_id: string;
        };
    };
}

export interface RzpCardDetails {
    method: 'card';
    'card[name]': string;
    'card[number]': string;
    'card[cvv]': string;
    'card[expiry_month]': string;
    'card[expiry_year]': string;
}
export interface RzpCardIntent extends RzpPgIntent, RzpCardDetails {}

// upi details
export interface RzpUpiDetails {
    method: 'upi';
    vpa: string;
}

export interface RzpNetBankingDetails extends RzpPgIntent{
    method: 'netbanking';
    bank: string;
}
export interface RzpUpiIntent extends RzpPgIntent, RzpUpiDetails {}

// wallet params
export type RzpWalletCodes =
    | 'mobikwik'
    | 'olamoney'
    | 'jiomoney'
    | 'airtelmoney'
    | 'amazonpay'
    | 'phonepe'
    | 'freecharge';
export const rzpWalletCodes: { [name: string]: RzpWalletCodes } = {
    mobikwik: 'mobikwik',
    olaMoney: 'olamoney',
    relianceJioMoney: 'jiomoney',
    airtelMoney: 'airtelmoney',
    amazonPay: 'amazonpay',
    phonePe: 'phonepe',
    freecharge: 'freecharge',
};
export interface RzpWalletDetails {
    method: 'wallet';
    wallet: RzpWalletCodes;
}
export interface RzpWalletIntent extends RzpPgIntent, RzpWalletDetails {}

// gpay webflow
export interface RzpGooglePayDetails {
    method: 'upi';
    '_[flow]': 'intent';
    upi_provider: 'google_pay';
    notes?: Object;
}

// gpay web intent
export interface RzpGooglePayWebIntentDetails {
    method: 'upi';
}
export interface RzpGooglePayWebIntentDirective {
    gpay: true;
}
export interface RzpGooglePayWebIntent extends RzpGooglePayWebIntentDetails {}

export type OmittedPgIntent = Omit<RzpPgIntent, 'currency' | 'notes'>;
export interface RzpGooglePayIntent extends OmittedPgIntent, RzpGooglePayDetails {}

export type RzpCategoryDetails =
    | RzpCardDetails
    | RzpUpiDetails
    | RzpWalletDetails
    | RzpGooglePayDetails
    | RzpGooglePayWebIntentDetails;

export type RazorPayUnifiedIntent =
    | RzpCardIntent
    | RzpUpiIntent
    | RzpWalletIntent
    | RzpGooglePayIntent
    | RzpGooglePayWebIntent;

export type RzpUnifiedDirectives = RzpGooglePayWebIntentDirective;

class RazorpayService {
    rzp: any;

    constructor() {}

    init(params: { key?: string; image?: string; shortLink: string }) {
        params.key = params.key || process.env.PREACT_RZP_KEY;
        params.image =
            params.image ||
            'https://khatabook.com/common/logo/cropped-logo-512-192x192-52fbfc1d80.png';
        this.rzp = window.Razorpay({
            ...params,
            redirect: true,
            callback_url: `${Constants.callbackUrl}?shortLink=${params.shortLink}`,
        });
        // @ts-ignore
        this.rzp.on('payment.success', (...args) => console.log(args, 'ggwp'));

        // @ts-ignore
        this.rzp.on('payment.error', (...args) => console.log(args, 'gg'));
    }

    addSuccessHandler() {}

    addFailureHandler() {}

    createUpiPayment(params: RzpUpiIntent) {
        this.rzp.createPayment(params);
    }

    createWalletPayment(params: RzpWalletIntent) {
        this.rzp.createPayment(params);
    }

    createCardPayment(params: RzpCardIntent) {
        this.rzp.createPayment(params);
    }

    createNetBankingPayment(params: RzpNetBankingDetails) {
        this.rzp.createPayment(params);
    }

    async verifyUpiId(upiId: string): Promise<boolean> {
        try {
            console.log('verifyUpiId', upiId);
            await new Promise((r) => setTimeout(r, 500));
            const split = upiId.split('@');
            if (split.length !== 2) return false;
            if (split[1].length < 3) return false;
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}

export default RazorpayService;
