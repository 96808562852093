import { FunctionalComponent, h } from 'preact';
import { PaymentState } from '../../helpers/use-payments-helper';
import { i18nKeyValue } from '../../i18n/strings/types';
import { getUserAgentInfo } from '../../utils/get-user-agent';
import {Tenant} from "../../services/payments";
interface Props {
    isMobileDevice: boolean;
    handleBackClick: Function;
    langStrings: i18nKeyValue;
    paymentState: PaymentState | null;
    tenant: string;
}
export const Header: FunctionalComponent<Props> = (props: Props) => {
    const { isMobileDevice, paymentState, tenant, handleBackClick, langStrings } = props;
    function handleArrowClick() {
        handleBackClick();
    }
    return isMobileDevice ? (
        <nav class="theme-background-color">
            <div class="header-left">
                <button onClick={handleArrowClick} id="nav-toggle">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 0L0 8L8 16L9.41 14.591L3.83 9H16V7H3.83L9.42 1.41L8 0Z"
                            fill="white"
                        ></path>
                    </svg>
                </button>
                <div class="header-text">
                    <a href="#">
                        <span>
                            {paymentState && paymentState.processing
                                ? langStrings.webview_paymentprocessing_header
                                : langStrings.webview_header}
                        </span>
                    </a>
                </div>
            </div>
        </nav>
    ) : (
        <nav>
            {tenant === 'pk' && <img src="/assets/images/pagaarkhata.svg" class="header-logo" alt="Logo" />}
            {(tenant === 'ba' || tenant === Tenant.BIZ_A_COINS) && <img src="/assets/images/bizanalyst.svg" class="header-logo" alt="Logo" />}
            {tenant !== 'pk' && tenant !== 'ba' && tenant !== Tenant.BIZ_A_COINS && (
                <img src="/assets/images/khatabook.svg" class="header-logo" alt="Logo" />
            )}
        </nav>
    );
};
