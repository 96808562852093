import * as app from '../package.json';
export const appVersion = app.version;
export const appName = 'My App';

if (!process.env.PREACT_APP_API_HOST) {
    throw new Error('Missing API_HOST env variable');
}
if (!process.env.PREACT_APP_SEGMENT_KEY) {
    throw new Error('Missing SEGMENT_KEY env variable');
}

// https://dev.to/jam3/managing-env-variables-for-provisional-builds-h37
// https://create-react-app.dev/docs/adding-custom-environment-variables/
// console.log(process.env.PREACT_APP_ENV)
export const isProd = process.env.PREACT_APP_ENV === 'production';
export const isDev =
    process.env.PREACT_APP_ENV === 'development' || process.env.PREACT_APP_ENV === 'staging';
