import {h} from 'preact';


export const ControlWrapper = (
    {
        shouldShow,
        children
    }: {
        shouldShow: boolean;
        children: any;
    }) => {

    if (shouldShow) {
        return children
    }
    return null

}