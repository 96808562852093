import { Utils } from './utils';

class _Constants {
    private readonly baseApiUrl = process.env.PREACT_APP_API_HOST;
    readonly paymentsShortLinkUrl = `${this.baseApiUrl}/payments/v1/platform/short-link`;
    readonly createPgOrderUrl = `${this.baseApiUrl}/payments/v1/platform/gateway-order`;
    readonly defaultUserId = '11111111-1111-1111-1111-111111111111';
    readonly callbackUrl = `${this.baseApiUrl}/payments/v1/payment-link-webhook/razorpay/return`;
    readonly fetchCardType= `${this.baseApiUrl}/payments/v1/platform/card-type`
    readonly fetchNetBankingOptions= `${this.baseApiUrl}/payments/v1/platform/net-banking-allowed-banks`
    readonly syncPspUsedApp= `${this.baseApiUrl}/loans-service/apis/v1/users/update-psp`
}

const Constants = new _Constants();
export default Constants;
console.log('process.env', Constants.paymentsShortLinkUrl);
