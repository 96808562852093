export type browserNameType =
    | 'chrome'
    | 'safari'
    | 'uc'
    | 'mi'
    | 'opera'
    | 'vivo'
    | 'samsung'
    | 'oppo'
    | 'firefox'
    | 'edge'
    | 'brave'
    | 'other';
export type osNameType = 'android' | 'ios' | 'macos' | 'windows' | 'linux' | 'other';

export interface UserAgentInfo {
    isChrome: boolean;
    isSafari: boolean;
    userAgent: string;
    browser: browserNameType;
    os: osNameType;
}

export const getUserAgentInfo = (): UserAgentInfo => {
    let _isChrome: boolean = false;
    let _isSafari: boolean = false;
    let _browser: browserNameType = 'other';
    let _os: osNameType = 'other';

    // get os
    const isAndroid =
        navigator.userAgent.indexOf('Android') !== -1 ||
        navigator.userAgent.indexOf('android') !== -1;
    const isIos =
        navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iOS') !== -1;
    const isMacOs =
        navigator.userAgent.indexOf('Macintosh') !== -1 ||
        navigator.userAgent.indexOf('macintosh') !== -1;
    const isWindows =
        navigator.userAgent.indexOf('Windows') !== -1 ||
        navigator.userAgent.indexOf('windows') !== -1;
    const isLinux =
        navigator.userAgent.indexOf('Linux') !== -1 || navigator.userAgent.indexOf('linux') !== -1;
    if (isAndroid) {
        _os = 'android';
    } else if (isIos) {
        _os = 'ios';
    } else if (isMacOs) {
        _os = 'macos';
    } else if (isWindows) {
        _os = 'windows';
    } else if (isLinux) {
        _os = 'linux';
    } else {
        _os = 'other';
    }

    // check if safari on ios
    const isSafariOnIphone =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('iPhone') !== -1 &&
        navigator.userAgent.indexOf('CriOS') === -1;

    if (_os === 'ios' && isSafariOnIphone) {
        _isSafari = true;
        _browser = 'safari';
    }

    // find if chrome or its variation
    const isChromium =
        navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('CriOS') !== -1;
    const isFirefox =
        navigator.userAgent.indexOf('Firefox') !== -1 ||
        navigator.userAgent.indexOf('firefox') !== -1;
    const isEdge =
        navigator.userAgent.indexOf('EdgA') !== -1 ||
        navigator.userAgent.indexOf('Edge') !== -1 ||
        navigator.userAgent.indexOf('edge') !== -1 ||
        navigator.userAgent.indexOf('MicrosoftEdge') !== -1;
    const isUc = navigator.userAgent.indexOf('UCBrowser') !== -1;
    const isMi =
        navigator.userAgent.indexOf('XiaoMi') !== -1 ||
        navigator.userAgent.indexOf('MiuiBrowser') !== -1;
    const isOpera =
        navigator.userAgent.indexOf('OPR') !== -1 || navigator.userAgent.indexOf('Opera') !== -1;
    const isVivo = navigator.userAgent.indexOf('VivoBrowser') !== -1;
    // navigator.userAgent.indexOf("vivo") !== -1;
    const isSamsung =
        navigator.userAgent.indexOf('SamsungBrowser') !== -1 ||
        navigator.userAgent.indexOf('samsung') !== -1;
    const isOppo =
        navigator.userAgent.indexOf('OppoBrowser') !== -1 ||
        navigator.userAgent.indexOf('oppo') !== -1;

    // brave browser works on a different API to check if it's brave or not
    // const isBrave = await navigator.brave.isBrave()

    // is chrome
    if (isChromium === true) {
        _isChrome = true;
        _browser = 'chrome';
    }

    // but wait, could be a derivative of chrome on android or ios
    if (isUc || isMi || isOpera || isVivo || isSamsung || isOppo || isFirefox || isEdge) {
        _isSafari = false;
        _isChrome = false;

        if (isUc) {
            _browser = 'uc';
        }
        if (isMi) {
            _browser = 'mi';
        }
        if (isOpera) {
            _browser = 'opera';
        }
        if (isVivo) {
            _browser = 'vivo';
        }
        if (isSamsung) {
            _browser = 'samsung';
        }
        if (isOppo) {
            _browser = 'oppo';
        }
        if (isFirefox) {
            _browser = 'firefox';
        }
        if (isEdge) {
            _browser = 'edge';
        }
    }

    return {
        os: _os,
        isChrome: _isChrome,
        isSafari: _isSafari,
        browser: _browser,
        userAgent: navigator.userAgent,
    };
};
