import { useCallback, useEffect, useLayoutEffect, useState } from 'preact/hooks';
import { defaultLanguage, languageStringsMapper } from '../i18n/language-mapper';
import { i18nKeyValue } from '../i18n/strings/types';
import { supportedLangsType } from '../i18n/types';

const KhatabookLanguageCodeMap: {
    [key: number]: supportedLangsType;
} = {
    [-1]: 'en',
    1: 'en',
    2: 'hi',
    3: 'en_US',
    4: 'mr',
    5: 'gu',
    6: 'pa',
    7: 'ta',
    8: 'te',
    9: 'kn',
    10: 'bn',
    11: 'ml',
    13: 'or',
    14: 'as',
};

export interface IUtilsHelper {
    langStrings: i18nKeyValue;
    isMobileDevice: boolean;
    isQREnlarged: boolean;
    enlargeQRImage: Function;
    closeQRImage: Function;
    isOffline: boolean;
}

export const useUtilsHelper = (): IUtilsHelper => {
    const [langStrings, setLangStrings] = useState<i18nKeyValue>(defaultLanguage);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [isQREnlarged, setIsQREnlarged] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    const getLanguage = (): supportedLangsType => {
        return localStorage.getItem('lang') as supportedLangsType;
    };

    useEffect(() => {
        let lang = getLanguage();
        languageStringsMapper(lang).then((newLangFile) => {
            setLangStrings(newLangFile);
        });
    }, [getLanguage()]);
    useLayoutEffect(() => {
        function windowResized() {
            setIsMobileDevice(window.innerWidth < 1000);
        }
        window.addEventListener('resize', windowResized);
        windowResized();
        return () => window.removeEventListener('resize', windowResized);
    }, []);
    const enlargeQRImage = useCallback(() => {
        setIsQREnlarged(true);
    }, [isQREnlarged]);

    const closeQRImage = useCallback(() => {
        setIsQREnlarged(false);
    }, [isQREnlarged]);
    useEffect(() => {
        setIsOffline(!navigator.onLine);
    }, [navigator.onLine]);

    setInterval(() => {
        setIsOffline(!navigator.onLine);
    }, 1000);

    return {
        langStrings,
        isMobileDevice,
        isQREnlarged,
        enlargeQRImage,
        closeQRImage,
        isOffline,
    };
};
