import { FunctionalComponent, h } from 'preact';
import { useCallback, useState, useEffect } from 'preact/hooks';
import { CardDetails, IPaymentsHelper } from '../../helpers/use-payments-helper';
import { i18nKeyValue } from '../../i18n/strings/types';
import {CardType, DestinationFeature, IOrderDetails, PaymentOptions, Tenant, TenantMap} from '../../services/payments';
import { ICardDetails, IExpiryValidation, useCardHelper } from './use-card-helper';

type Props = {
    isMobileDevice: boolean;
    payment: IOrderDetails;
    langStrings: i18nKeyValue;
    paymentOptions: any;
    tenant: Tenant;
} & Pick<IPaymentsHelper, 'makeCardPayment' | 'isCreatingCardOrder'>;

export const CardPayment: FunctionalComponent<Props> = (props: Props) => {
    const { isMobileDevice, payment, langStrings } = props;
    const { mdrCharges } = payment;
    const [cardType, setCardType] = useState(CardType.INVALID);
    const [ isCreditCard, setIsCreditCard ] = useState(false);
    const [cardError, setCardError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [nameError, setNameError] = useState('');
    const [CVVError, setCVVError] = useState('');
    const [convenienceFee, setConvenienceFee] = useState('0');
    // const [isCheckingCardType, updateCardTypeStatus]= useState(false)

    const getElements = (inputIds: string[]): HTMLInputElement[] => {
        return inputIds.map((id) => document.getElementById(id) as HTMLInputElement);
    };

    let [cardNum, name, expiry, cvv] = getElements(['cardNum', 'name', 'expiry', 'cvv']);

    useEffect(() => {
        [cardNum, name, expiry, cvv] = getElements(['cardNum', 'name', 'expiry', 'cvv']);
    }, [cardNum, name, expiry, cvv]);

    const getMdr = (type: CardType) => {
        if (!payment.surcharge) return 0;
        let perc = mdrCharges ? mdrCharges.CC.mdr : 0;
        if (mdrCharges.CC) {
            perc = mdrCharges.CC.mdr;
            if (mdrCharges.CC.types && mdrCharges.CC.types![type]) {
                perc = mdrCharges.CC.types![type].mdr;
            }
        }
        return perc;
    };

    const [mdr, setMdr] = useState(getMdr(cardType));
    let charge = payment.amount / 100;
    let chargeWithConvenience = (charge / 100) * (100 + mdr);
    setConvenienceFee(Number(chargeWithConvenience - charge).toFixed(2));
    const [amount, setAmount] = useState(parseFloat(chargeWithConvenience.toFixed(2)));

    const {
        getCardNumDetails,
        validateCardNum,
        formatExpiry,
        cleanCardNum,
        validateExpiry,
        validateCardholderName,
        validateEnteredCardForCC
    } = useCardHelper();

    const handleCardNumChange = (e: any) => {
        if (Number.isInteger(parseInt(e.key))) {
            let cardNumToValidate = cardNum.value;
            let v: ICardDetails = getCardNumDetails(cardNumToValidate);
            let position = e.target.selectionStart;
            cardNum.value = v.cardNum;

            // Skip a character if it's a space
            if (v.cardNum[position - 1] === ' ') position++;
            e.target.selectionStart = position;
            e.target.selectionEnd = position;
            let perc = getMdr(v.type);
            let charge = payment.amount / 100;
            let chargeWithConvenience = (charge / 100) * (100 + perc);

            setCardType(v.type);
            setMdr(perc);
            setConvenienceFee(Number(chargeWithConvenience - charge).toFixed(2));
            setAmount(parseFloat(chargeWithConvenience.toFixed(2)));
            setCardError('');
        }
    };
    const onCardNumKeyPress = (e: any) => {
        if (!(e.key == 'Backspace' || !isNaN(parseInt(e.key)))) {
            e.preventDefault();
        }
        let cardNumToValidate = cardNum.value + e.key;
        let v: ICardDetails = getCardNumDetails(cardNumToValidate);
        if (cleanCardNum(cardNumToValidate) !== cleanCardNum(v.cardNum)) {
            e.preventDefault();
        }
    };

    const handleExpiryChange = (e: any) => {
        if (e.key !== 'Backspace') {
            e.target.value = formatExpiry(e.target.value);
        }
    };

    const stopChange = (e: any) => {
        e.preventDefault();
    };

    const allowNumOnly = (e: any) => {
        if (!(e.key == 'Backspace' || !isNaN(parseInt(e.key)))) {
            e.preventDefault();
        } else {
            setExpiryError('');
        }
    };

    const updateExpiryError = (): boolean => {
        let expiryCheck: IExpiryValidation = validateExpiry(expiry.value);
        if (expiry.value.length == 0 || expiryCheck.isInvalid) {
            setExpiryError(langStrings.invalid_expiry);
            return false;
        } else if (expiryCheck.isExpired) {
            setExpiryError(langStrings.webview_cardcvverror);
            return false;
        } else {
            setExpiryError('');
            return true;
        }
    };

    const updateCardNumError = (e?: any): boolean => {
        if (cardNum.value.length == 0 || !validateCardNum(cardNum.value)) {
            setCardError(langStrings.webview_cardnumbererror);
            return false;
        } else {
            setCardError('');
            return true;
        }
    };

    const updateCVVError = (e?: any): boolean => {
        if (
            cvv.value.length == 0 ||
            cvv.value.length < 3 ||
            cvv.value.length > 4 ||
            cvv.value.indexOf('.') !== -1
        ) {
            setCVVError(langStrings.invalid_cvv);
            return false;
        } else {
            setCVVError('');
            return true;
        }
    };

    const updateCardholderNameError = (e?: any): boolean => {
        if (name.value.length == 0 || !validateCardholderName(name.value)) {
            setNameError(langStrings.webview_cardholdernameerror);
            return false;
        } else {
            setNameError('');
            return true;
        }
    };

    const submitForPay = async() => {
        let isFormValid = true;
        isFormValid = updateCardNumError() && isFormValid;
        isFormValid = updateExpiryError() && isFormValid;
        isFormValid = updateCardholderNameError() && isFormValid;
        isFormValid = updateCVVError() && isFormValid;

        const isCreditCardAllowed = props.paymentOptions.includes(PaymentOptions.CC)
        if(isFormValid && !isCreditCardAllowed){
            try {
                const cardNumber = cardNum.value
                const firstSixCardNum = cardNumber.split(' ').join('').substring(0,6)
                const {isCreditCard}= await validateEnteredCardForCC({cardNumber:firstSixCardNum, tenant:TenantMap[props.tenant] as DestinationFeature})
                if(isCreditCard){
                    setIsCreditCard(true)
                    setCardError(langStrings.credit_card_not_allowed);
                    isFormValid = false;
                } else {
                    setIsCreditCard(false)
                    isFormValid= true;
                }
            }catch (e) {
                setCardError(e?.message)
                isFormValid = false
            }
        }

        // Replace with no errors
        if (isFormValid) {
            let paymentDetails: CardDetails = {
                cardNumber: cardNum.value,
                nameOnCard: name.value.toUpperCase(),
                expiryMonth: parseInt(expiry.value.split('/')[0]),
                expirtyYear: parseInt(expiry.value.split('/')[1]),
                cvv: cvv.value,
                cardType,
                isCreditCard

            };
            props.makeCardPayment(paymentDetails);
        }
    };

    return (
        <div className="card-body cc-pay-card">
            <div className="form-container">
                <div className="card-form">
                    <div className="card-number">
                        <input
                            placeholder={langStrings.webview_cardnumber}
                            type="tel"
                            onKeyPress={(e) => {
                                onCardNumKeyPress(e);
                            }}
                            onKeyUp={(e) => {
                                handleCardNumChange(e);
                            }}
                            onBlur={(e) => {
                                updateCardNumError(e);
                            }}
                            className={`card-number ${cardError ? 'input-error' : ''}`}
                            id="cardNum"
                        />
                        {cardError && <p className="error">{cardError}</p>}
                    </div>
                    <input
                        placeholder={langStrings.webview_cardname}
                        onBlur={(e) => {
                            updateCardholderNameError(e);
                        }}
                        type="text"
                        id="name"
                        className={`card-name ${nameError ? 'input-error' : ''}`}
                    />
                    {nameError && <p className="error">{nameError}</p>}
                    <input
                        placeholder={langStrings.webview_cardexpiry}
                        type="tel"
                        maxLength={5}
                        onKeyDown={(e) => {
                            allowNumOnly(e);
                        }}
                        onKeyPress={(e) => {
                            allowNumOnly(e);
                        }}
                        onKeyUp={(e) => {
                            handleExpiryChange(e);
                        }}
                        onChange={(e) => {
                            stopChange(e);
                        }}
                        onPaste={(e) => {
                            stopChange(e);
                        }}
                        onBlur={(e) => {
                            updateExpiryError();
                        }}
                        className={`expiry ${expiryError ? 'input-error' : ''}`}
                        id="expiry"
                    />
                    <input
                        placeholder={langStrings.webview_cardcvv}
                        id="cvv"
                        type="number"
                        max={9999}
                        onBlur={(e) => {
                            updateCVVError();
                        }}
                        pattern="\d{0, 4}"
                        className={`cvv ${CVVError ? 'input-error' : ''}`}
                    />
                    <div class="card-errors">
                        {expiryError && <p className="error">{expiryError}</p>}
                        {CVVError && <p className="error cvv-error">{CVVError}</p>}
                    </div>
                    <button onClick={submitForPay} className="action-button">
                        {langStrings.webview_pay} {amount}
                    </button>
                    {isMobileDevice && mdrCharges.CC && mdr > 0 && (
                        <div className="convience">
                            {langStrings.webview_surchargemsg.replace(
                                '{$PaymentAmount}',
                                convenienceFee + ''
                            )}
                            <span className="fee">
                                {langStrings.webview_desktoppaycta.replace(
                                    '{$PaymentAmount}',
                                    '₹' + amount
                                )}
                            </span>
                        </div>
                    )}
                    {!isMobileDevice && (
                        <div className="charges">
                            <div className="cards-image">
                                <img src="/assets/images/cards.svg" alt="" />
                            </div>
                            {mdrCharges.CC && mdr > 0 && (
                                <div className="convience">
                                    <p>
                                        {langStrings.webview_desktopsurchargemsg.replace(
                                            '{$surcharge%}',
                                            mdr.toString() + '%'
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isMobileDevice && (
                <div className="cards-image">
                    <img src="/assets/images/cards.svg" alt="" />
                </div>
            )}
        </div>
    );
};
