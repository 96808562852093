import { useCallback, useEffect, useState, useRef } from 'preact/hooks';
import {
    IOrderDetails,
    IPgIntent,
    PaymentOptions,
    PaymentService,
    Tenant,
    ICreatePgOrderResponse,
    BaseError,
    CardType,
    OrderStatus, NetBankingItem, TenantMap,
} from '../services/payments';
import RazorpayService from '../services/rzp';
import { RzpWalletCodes } from '../services/rzp';
import queryString from 'query-string';
import { setCommonEventProperties } from '../analytics/event.setup';
import { useAnalyticsEvents } from '../analytics/events.home';
import { Utils } from '../utils/utils';
import { appVersion } from '../env';
import Constants from '../utils/constants';

const CLOSE_SDK_DELAY_MS = 2500;
export interface CardDetails {
    cardNumber: string;
    nameOnCard: string;
    expiryMonth: number;
    expirtyYear: number;
    cvv: string;
    cardType: CardType;
    isCreditCard: boolean
}

declare global {
    interface Window {
        Android: any;
        webkit: {
            messageHandlers: any;
        };
    }
}

export interface IPaymentsHelper {
    isAppLoading: boolean;
    tenant: string;
    theme: string;
    paymentMode: string;
    shortLink: string | null;
    updatePaymentMode: Function;
    handleBackClick: Function;
    paymentOrderDetail: IOrderDetails | null;
    makeUpiPayment: (verifiedUpiId: string) => void;
    makeCardPayment: (cardDetails: CardDetails) => void;
    makeWalletPayment: (walletCode: RzpWalletCodes) => void;
    upiIntentOrder: ICreatePgOrderResponse | null;
    setShouldPoll: (flag: boolean) => void;
    getSurchargeForCardOrWallet: (params: { cardNumber?: string; isWallet?: boolean }) => void;
    isCreatingCardOrder: boolean;
    isCreatingWalletOrder: boolean;
    isCreatingUpiOrder: boolean;
    paymentError: string | null;
    hideError: Function;
    displayError: boolean;
    paymentState: PaymentState | null;
    setPaymentState: Function;
    completeKYCClick: Function;
    whatsappClick: Function;
    contactUsClick: Function;
    netBankingSupportedBankList: Array<{ bankName: string; bankCode: string }>;
    initiateNetBanking: Function;
}
export enum PaymentMode {
    UPI = 'UPI',
    CARD = 'CARD',
    WALLET = 'WALLET',
    NET_BANKING= 'NET_BANKING'
}

export interface PaymentState {
    processing: boolean;
    mode: PaymentMode;
}

function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef<Function>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const usePaymentHelper = (): IPaymentsHelper => {
    const AVAILABLE_THEMES = ['kb', 'ivr', 'b2b', 'pk', 'my'];
    const DEFAULT_THEME = 'kb';
    const paymentService = new PaymentService();
    const rzpService = new RazorpayService();
    const [paymentOrderDetail, setPaymentOrderDetail] = useState<IOrderDetails | null>(null);
    const [isAppLoading, setIsAppLoading] = useState(true);
    const [paymentMode, setPaymentMode] = useState(PaymentMode.UPI);
    const [shortLink, setShortLink] = useState<string>('');
    const [tenant, setTenant] = useState<Tenant | ''>('');
    const [theme, setTheme] = useState<typeof AVAILABLE_THEMES[number] | ''>('');
    const [displayError, setDisplayError] = useState(true);
    const [isCreatingUpiOrder, setIsCreatingUpiOrder] = useState(true);
    const [upiIntentOrder, setUpiIntentOrder] = useState<ICreatePgOrderResponse | null>(null);
    const [upiInentOrderError, setUpiIntentOrderError] = useState<BaseError | null>(null);
    const [paymentError, setPaymentError] = useState<null | string>(null);
    const [paymentState, setPaymentState] = useState<PaymentState | null>(null);
    const [isCreatingCardOrder, setIsCreatingCardOrder] = useState(false);
    const [isCreatingWalletOrder, setIsCreatingWalletOrder] = useState(false);
    const [shouldPoll, setShouldPoll] = useState<boolean>(false);
    const [netBankingSupportedBankList, updateNetBankingList] = useState<Array<{ bankName: string; bankCode: string }>>([])
    const CONTACT_US_NUMBER = '+919606800706';
    const WHATSAPP_NUMBER = '+919606800706';

    const analytics = useAnalyticsEvents();

    const fetchPaymentOrderDetail = async (
        params: {
            shortLink?: string;
            tenant?: string;
        },
        retryCount = 0
    ) => {
        try {
            if (!navigator.onLine) return;
            const order = await new PaymentService().fetchShortLinkDetails({
                shortLink: params.shortLink || shortLink,
            });
            console.log({ order });
            if (isAppLoading) {
                setCommonEventProperties({
                    tenant: params.tenant,
                    platform: Utils.getPlatform(),
                    userId: order.payer.userId,
                    appVersion,
                    orderId: order.kbOrderId,
                    amountSU: order.amount,
                    surcharge: order.surcharge,
                    paymentModes: order.paymentOptions,
                });
                analytics.homePageLoad();
                setIsAppLoading(false);
                if (order?.status === OrderStatus.PENDING) {
                    createUpiIntentPgOrder(params.shortLink || shortLink);
                }
            }
            if (shouldPoll && order.status === 'SUCCESS') {
                setShouldPoll(false);
            }
            if (order.status === 'CANCELLED') {
                setShouldPoll(false);
                closeSDKWithDelay(true, order);
            }
            if (order.status === 'SUCCESS') {
                setPaymentState(null);
                analytics.closeSdk({status: OrderStatus.SUCCESS});
                closeSDKWithDelay(true, order);
            }
            localStorage.setItem('lang', order.lang);
            setPaymentOrderDetail(order);
        } catch (e) {
            setCommonEventProperties({
                userId: Constants.defaultUserId,
                tenant: params.tenant!,
                appVersion,
                platform: Utils.getPlatform(),
            });
            analytics.homePageLoadError(e);
        }
    };

    const updatePaymentMode = useCallback(
        (mode: PaymentMode) => {
            setPaymentMode(mode);
        },
        [paymentMode]
    );

    const hideError = useCallback(() => {
        const path = window.location.pathname.split('/');
        const sl = path[2];
        window.history.pushState({ page: 'Payment' }, 'Payment', sl);
        setDisplayError(false);
        setPaymentError(null);
    }, [displayError]);

    const pollOrderStatus = () => {
        useInterval(() => {
            fetchPaymentOrderDetail({ shortLink, tenant });
        }, 2500);
    };

    useEffect(() => {
        const path = window.location.pathname.split('/');
        const t = path[1] as Tenant,
            sl = path[2];
        setTenant(t);
        configureTheme(t);
        setShortLink(sl);
        const parsed = queryString.parse(location.search);
        if (parsed.error && typeof parsed.error === 'string') {
            const parsedError = JSON.parse(parsed.error);
            const msg = parsedError.message || 'Something went wrong';
            setPaymentError(msg);
            console.log('error: ', msg);
            analytics.makePaymentFailure({}, parsedError);
            //return;
        }
        // TODO: Throw if tenant or shortLink don't exist

        fetchPaymentOrderDetail({ shortLink: sl, tenant: t });
    }, []);

    useEffect(() => {
        var searchParams = new URLSearchParams(location.search);
        let client = searchParams.get('client');
        const path = window.location.pathname.split('/');
        const sl = path[2];
        if (client) {
            localStorage.setItem(sl + '-client', client);
        }
    }, [location.search]);

    const getNetBankingSupportedBanks = async () => {
        const list = await new PaymentService().getNetBankingOptions({feature: tenant ? TenantMap[tenant] : ''})
        updateNetBankingList(list)
    }

    useEffect(()=>{
        const isNetBankingSupported = paymentOrderDetail?.paymentOptions.includes(PaymentOptions.NET_BANKING)
        if(isNetBankingSupported && netBankingSupportedBankList.length===0){
            getNetBankingSupportedBanks()
        }


    },[paymentOrderDetail])

    pollOrderStatus();

    const createUpiIntentPgOrder = async (shortLink: string) => {
        setIsCreatingUpiOrder(true);

        try {
            const upiIntentPgOrder = await paymentService.createPgOrder({
                shortLink,
                paymentMode: PaymentOptions.UPI_INTENT,
            });
            console.log('upiIntentPgOrder', upiIntentPgOrder);
            setUpiIntentOrder(upiIntentPgOrder);
        } catch (e) {
            setUpiIntentOrderError(e);
        }

        setIsCreatingUpiOrder(false);
    };

    const closeSDKWithDelay = (paymentProcessed: boolean, order: IOrderDetails) => {
        setTimeout(() => {
            closeSDK(paymentProcessed, order);
        }, CLOSE_SDK_DELAY_MS);
    };

    const navigateToUrl = (url: string) => {
        window.location.href = url;
    };

    const closeSDK = (paymentProcessed: boolean, order?: IOrderDetails) => {
        console.log('closing SDK');
        if (window.Android?.closeClick) {
            window.Android.closeClick();
        } else if (window?.webkit?.messageHandlers) {
            window.webkit.messageHandlers.closeSDK.postMessage({
                message: 'closeSDK',
            });
        } else if (
            localStorage.getItem(shortLink + '-client') == 'web' &&
            paymentOrderDetail &&
            paymentOrderDetail.callbackUrl
        ) {
            let {callbackUrl} = paymentOrderDetail;
            const containsQueryString=callbackUrl.includes('?')
            callbackUrl += containsQueryString ? '&' : '?'
            callbackUrl += `shortLinkId=${shortLink}&processed=${paymentProcessed}`;
            if (order) {
                callbackUrl = `${callbackUrl}&status=${order?.status}&amount=${order?.amount}`
            }
            navigateToUrl(callbackUrl);
        }
    };

    const configureTheme = (tenent: string) => {
        if (AVAILABLE_THEMES.includes(tenent)) {
            setTheme(tenent);
        } else {
            setTheme(DEFAULT_THEME);
        }
    };

    const handleBackClick = () => {
        if (paymentState && !paymentState.processing) {
            setPaymentState(null);
        } else {
            closeSDK(false);
        }
    };

    const makeUpiPayment = async (verifiedUpiId: string) => {
        try {
            analytics.makePayment({ mode: PaymentMode.UPI, upiMode: 'collect' });

            // First create the pg order with payments service
            const pgOrder = await paymentService.createPgOrder({
                shortLink: shortLink,
                paymentMode: PaymentOptions.UPI,
            });

            const upiIntent = pgOrder.intents.find((intent) => intent.type === 'pg') as IPgIntent;

            rzpService.init({
                key: upiIntent.appId,
                shortLink: shortLink,
            });
            rzpService.createUpiPayment({
                method: 'upi',
                order_id: upiIntent.orderId,
                amount: pgOrder.amountSU,
                email: 'payments@khatabook.com',
                contact: paymentOrderDetail!.payer.phone,
                currency: 'INR',
                vpa: verifiedUpiId,
            });
        } catch (e) {
            analytics.makePaymentFailure({ mode: PaymentMode.UPI, upiMode: 'collect' }, e);
        }
    };

    const makeCardPayment = async (cardDetails: CardDetails) => {
        try {
            setPaymentState({
                mode: PaymentMode.CARD,
                processing: true,
            });
            analytics.makePayment({ mode: PaymentMode.CARD });
            setIsCreatingCardOrder(true);
            const validPaymentModeType = [CardType.AMERICAN_EXPRESS, CardType.RUPAY];
            const paymentModeType = validPaymentModeType.includes(cardDetails.cardType)
                ? cardDetails.cardType
                : undefined;
            const pgOrder = await paymentService.createPgOrder({
                shortLink,
                paymentMode: cardDetails.isCreditCard ? PaymentOptions.CC : PaymentOptions.DC,
                paymentModeType,
            });

            const cardIntent = pgOrder.intents.find((intent) => intent.type === 'pg') as IPgIntent;
            console.log('card pg order', pgOrder);
            rzpService.init({
                key: cardIntent.appId,
                shortLink: shortLink,
            });

            rzpService.createCardPayment({
                order_id: cardIntent.orderId,
                amount: pgOrder.amountSU,
                currency: 'INR',
                method: 'card',
                email: 'payments@khatabook.com',
                contact: paymentOrderDetail!.payer.phone,
                'card[name]': cardDetails.nameOnCard,
                'card[number]': cardDetails.cardNumber,
                'card[cvv]': cardDetails.cvv,
                'card[expiry_month]': String(cardDetails.expiryMonth),
                'card[expiry_year]': String(cardDetails.expirtyYear),
            });
        } catch (e) {
            analytics.makePaymentFailure({ mode: PaymentMode.CARD }, e);
            console.log(e);
        }
    };

    const makeWalletPayment = async (walletCode: RzpWalletCodes) => {
        try {
            setPaymentState({
                mode: PaymentMode.WALLET,
                processing: true,
            });
            analytics.makePayment({ mode: PaymentMode.WALLET });
            setIsCreatingWalletOrder(true);

            const pgOrder = await paymentService.createPgOrder({
                shortLink,
                paymentMode: PaymentOptions.WALLET,
            });

            console.log('wallet pg order', pgOrder);

            const walletIntent = pgOrder.intents.find(
                (intent) => intent.type === 'pg'
            ) as IPgIntent;
            rzpService.init({
                key: walletIntent.appId,
                shortLink: shortLink,
            });

            rzpService.createWalletPayment({
                order_id: walletIntent.orderId,
                amount: pgOrder.amountSU,
                currency: 'INR',
                email: 'payments@khatabook.com',
                method: 'wallet',
                wallet: walletCode,
                contact: paymentOrderDetail!.payer.phone,
            });
        } catch (e) {
            console.log(e);
            analytics.makePaymentFailure({ mode: PaymentMode.WALLET }, e);
        }
    };

    const getSurchargeForMdrAndAmount = (mdr: number, amount: number) => {
        if (typeof mdr !== 'number' || typeof amount !== 'number') {
            throw { message: 'mdr and amount should be number' };
        }

        return Math.ceil((1 + mdr / 100) * amount);
    };

    const getSurchargeForCardOrWallet = (params: { cardNumber?: string; isWallet?: boolean }) => {
        const defaultSurcharge = {
            mdr: 0,
            amount: paymentOrderDetail?.amount,
        };

        if (!paymentOrderDetail || !paymentOrderDetail.surcharge) {
            return defaultSurcharge;
        }

        const mdrCharges = paymentOrderDetail.mdrCharges;
        if (paymentOrderDetail.surcharge) {
            if (params.isWallet) {
                const mdr = mdrCharges.WALLET.mdr;
                return getSurchargeForMdrAndAmount(mdr, paymentOrderDetail.amount);
            } else {
                // TODO: Depending on card type
                const cardType = 'AMERICAN_EXPRESS';
                const isCC = true;
                let mdr: number = isCC ? mdrCharges.CC.mdr : mdrCharges.DC.mdr;
                if (cardType && isCC && mdrCharges.CC.types && mdrCharges.CC.types[cardType]) {
                    mdr = mdrCharges.CC.types[cardType].mdr;
                } else if (cardType && mdrCharges.DC.types && mdrCharges.DC.types[cardType]) {
                    mdr = mdrCharges.DC.types[cardType].mdr;
                }

                return getSurchargeForMdrAndAmount(mdr, paymentOrderDetail.amount);
            }
        }

        return {
            amount: paymentOrderDetail?.amount,
            mdr: 0,
        };
    };

    const completeKYCClick = () => {
        analytics.completeKYCClick({
            userId: paymentOrderDetail!.payer.userId,
            feature: 'B2B',
            limitBucketId: paymentOrderDetail!.limitBucketId,
            velocityBucketId: paymentOrderDetail!.velocityBucketId,
            amountSU: paymentOrderDetail!.amount,
            KYCStatus: paymentOrderDetail!.isKYCDone,
        });
        //send event
        if (window.Android?.openKycFlow) {
            window.Android.openKycFlow();
        } else if (window?.webkit?.messageHandlers) {
            window.webkit.messageHandlers.openKycFlow.postMessage({
                message: 'openKycFlow',
            });
        }
    };

    const whatsappClick = () => {
        if (window.Android) {
            window.Android.openWhatsApp(WHATSAPP_NUMBER);
        } else if (window?.webkit?.messageHandlers) {
            window.webkit.messageHandlers.openWhatsApp.postMessage({
                message: 'openWhatsApp',
                params: {
                    number: WHATSAPP_NUMBER,
                },
            });
        }
    };

    const contactUsClick = () => {
        if (window.Android) {
            window.Android.makeCall(CONTACT_US_NUMBER);
        } else if (window?.webkit?.messageHandlers) {
            window.webkit.messageHandlers.makeCall.postMessage({
                message: 'makeCall',
                params: {
                    number: CONTACT_US_NUMBER,
                },
            });
        }
    };

    const initiateNetBanking= async (netBankDetails: NetBankingItem)=>{
        try {
            updatePaymentMode(PaymentMode.NET_BANKING)
            setPaymentState({
                mode: PaymentMode.NET_BANKING,
                processing: true,
            });
            analytics.makePayment({ mode: PaymentMode.NET_BANKING});

            // First create the pg order with payments service
            const pgOrder = await paymentService.createPgOrder({
                shortLink: shortLink,
                paymentMode: PaymentOptions.NET_BANKING,
                netBankingSelectedBank: netBankDetails.bankName
            });
            const netBankingIntent = pgOrder.intents.find((intent) => intent.type === 'pg') as IPgIntent;

            rzpService.init({
                key: netBankingIntent.appId,
                shortLink: shortLink,
            });
            rzpService.createNetBankingPayment({
                method: 'netbanking',
                bank: netBankDetails.bankCode,
                order_id: netBankingIntent.orderId,
                amount: pgOrder.amountSU,
                email: 'payments@khatabook.com',
                contact: paymentOrderDetail!.payer.phone,
                currency: 'INR',
            });
        }catch (e) {

        }

    }

    const resetState = () => {
        setPaymentOrderDetail(null);
        setIsAppLoading(true);
        setPaymentMode(PaymentMode.UPI);
        setDisplayError(true);
        setIsCreatingUpiOrder(true);
        setUpiIntentOrder(null);
        setUpiIntentOrderError(null);

        setPaymentError(null);

        setIsCreatingCardOrder(false);
        setIsCreatingWalletOrder(false);
        setShouldPoll(false);

        fetchPaymentOrderDetail({ shortLink, tenant });
    };

    return {
        tenant,
        theme,
        isAppLoading,
        paymentMode,
        updatePaymentMode,
        paymentOrderDetail,
        makeUpiPayment,
        makeCardPayment,
        makeWalletPayment,
        shortLink,
        upiIntentOrder,
        setShouldPoll,
        getSurchargeForCardOrWallet,
        isCreatingCardOrder,
        isCreatingWalletOrder,
        isCreatingUpiOrder,
        handleBackClick,
        paymentError,
        hideError,
        displayError,
        paymentState,
        setPaymentState,
        completeKYCClick,
        contactUsClick,
        whatsappClick,
        netBankingSupportedBankList,
        initiateNetBanking
    };
};
