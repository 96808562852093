import { FunctionalComponent, h } from 'preact';
import { IPaymentsHelper } from '../../helpers/use-payments-helper';
import { i18nKeyValue } from '../../i18n/strings/types';

type Props = {
    langStrings: i18nKeyValue;
} & Pick<IPaymentsHelper, 'makeWalletPayment' | 'isCreatingWalletOrder' | 'paymentOrderDetail'>;

export const WalletPayment: FunctionalComponent<Props> = (props: Props) => {
    let { surcharge, amount } = props.paymentOrderDetail!;
    const { langStrings } = props;
    let surchargeAmount = 0;
    const mdr = props.paymentOrderDetail!.mdrCharges.WALLET.mdr;
    if (surcharge) {
        amount = Math.ceil((1 + mdr / 100) * amount);
    }

    const amountToShow = (amount / 100).toFixed(2);
    const surchargeAmountToShow = ((amount - props.paymentOrderDetail!.amount) / 100).toFixed(2);
    return (
        <div className="card-body wallet-payment">
            {surcharge && (
                <div className="convience wallet-convience">
                    {langStrings.webview_desktopsurchargemsg.replace('{$surcharge%}', mdr + '%')}
                    <span className="fee">
                        {langStrings.webview_desktoppaycta.replace(
                            '{$PaymentAmount}',
                            '₹' + amountToShow
                        )}
                    </span>
                </div>
            )}
            <div className="payment-modes modes">
                {/* <div className="mode" onClick={() => props.makeWalletPayment('phonepe')}>
                    <div className="image">
                        <img src="/assets/images/phonepe.svg" alt="" />
                    </div>
                    <div className="title">PhonePe</div>
                </div> */}
                <div className="mode" onClick={() => props.makeWalletPayment('mobikwik')}>
                    <div className="image">
                        <img src="/assets/images/mobikwik.svg" alt="" />
                    </div>
                    <div className="title">MobiKwik</div>
                </div>
                <div className="mode" onClick={() => props.makeWalletPayment('freecharge')}>
                    <div className="image">
                        <img src="/assets/images/freecharge.svg" alt="" />
                    </div>
                    <div className="title">Freecharge</div>
                </div>
                <div className="mode" onClick={() => props.makeWalletPayment('airtelmoney')}>
                    <div className="image">
                        <img src="/assets/images/airtel.svg" alt="" />
                    </div>
                    <div className="title">Airtel Payments Bank</div>
                </div>
            </div>
        </div>
    );
};
