import { FunctionalComponent, h } from 'preact';
import { UpiApp, useUpiInputHelper } from '../../helpers/use-upi-input-helper';
import { IPaymentsHelper, PaymentMode } from '../../helpers/use-payments-helper';
import { IUpiIntent, Tenant } from '../../services/payments';
import { default as QRCode } from 'qrcode-svg';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { i18nKeyValue } from '../../i18n/strings/types';
import { Utils } from '../../utils/utils';

type Props = {
    enlargeQrCode: Function;
    langStrings: i18nKeyValue;
    setPaymentState: Function;
    isMobileDevice: boolean;
} & Pick<
    IPaymentsHelper,
    | 'makeUpiPayment'
    | 'upiIntentOrder'
    | 'setShouldPoll'
    | 'isCreatingUpiOrder'
    | 'paymentOrderDetail'
    | 'tenant'
>;

export const UPIPayment: FunctionalComponent<Props> = (props: Props) => {
    const { isMobileDevice, langStrings, setPaymentState } = props;
    const [isQRLoaded, setIsQRLoaded] = useState(false);
    const [displayUPIInput, setDisplayUPIInput] = useState(!isMobileDevice);

    const upiIntent = props.upiIntentOrder?.intents?.find((intent) => intent.type === 'upi') as
        | IUpiIntent
        | undefined;
    const {
        upiId,
        UPIApps,
        // TODO: use for input verification loader
        isVerifyingUpiId,
        isDisabled,
        verifyUpiId,
        upiAppClick,
        // TODO: use for showing verification error
        upiVerificationError,
        useNatveUPIIntent,
        setUpiId,
    } = useUpiInputHelper(upiIntent?.url, props);

    const [showAllUPIApps, setShowAllUPIApps] = useState(UPIApps.length < 7);
    useEffect(() => {
        setShowAllUPIApps(UPIApps.length < 7);
    }, [UPIApps.length]);

    const showUPIInput = useCallback(() => {
        setDisplayUPIInput(true);
    }, [displayUPIInput]);

    const appClick = (app: UpiApp) => {
        props.setShouldPoll(true);
        setPaymentState({
            mode: PaymentMode.UPI,
            processing: true,
        });
        upiAppClick(app, upiIntent?.url);
    };

    const triggerNativeIntent = () => {
        props.setShouldPoll(true);
        setPaymentState({
            mode: PaymentMode.UPI,
            processing: true,
        });
        upiAppClick(null, upiIntent?.url);
    };

    const enlargeQrCode = () => {
        props.enlargeQrCode();
    };

    const showAllApps = () => {
        setShowAllUPIApps(true);
    };

    if (upiIntent) {
        var svg = new QRCode({
            content: upiIntent!.url,
            padding: 4,
            width: 180,
            height: 180,
            color: '#000000',
            background: '#ffffff',
            ecl: 'M',
        }).svg();
        var container = document.getElementById('qr-img');
        if (container) {
            container!.innerHTML = svg;
            setIsQRLoaded(true);
        }
    }

    const isUpiCollectValid = (() => {
        if (
            (Utils.getPlatform() === 'desktop' &&
                props.paymentOrderDetail?.paymentOptions.includes('CC')) ||
            props.tenant === Tenant.IVR
        ) {
            return true;
        }
        return false;
    })();
    console.log({useNatveUPIIntent,showAllUPIApps,UPIApps})
    return (
        <div className={'upi-section'}>
            {isMobileDevice && (
                <div className="mobile-ui">
                    <div className={`card-body ${useNatveUPIIntent ? 'p-t-0' : ''}`}>
                        {!useNatveUPIIntent && (
                            <div className="payment-modes upi modes">
                                {UPIApps?.slice(0, showAllUPIApps ? UPIApps.length : 5).map(
                                    (app) => (
                                        <a className="mode" onClick={() => appClick(app)}>
                                            <div className="image">
                                                <img src={app.appIcon} alt="" />
                                            </div>
                                            <div className="title">{app.appName}</div>
                                        </a>
                                    )
                                )}
                                {!showAllUPIApps && (
                                    <a className="mode" onClick={() => showAllApps()}>
                                        <div className="image">
                                            <img src="/assets/images/more.svg" alt="" />
                                        </div>
                                        <div className="title">
                                            {langStrings.webview_upimoreapps}
                                        </div>
                                    </a>
                                )}
                                {UPIApps.length == 0 && (
                                    <div className="no-upi-app">
                                        <div className="image">
                                            <img src="/assets/images/no-upi.svg" alt="" />
                                        </div>
                                        <p className="info">{langStrings.webview_noupiappsmsg}</p>
                                        <p className="info">{langStrings.webview_noupiappsmsg2}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        {useNatveUPIIntent && (
                            <div onClick={triggerNativeIntent} class="native-upi-intent">
                                <img className="upi-button" src="/assets/images/arrow.svg" />
                                <p className="title">
                                    {langStrings.webview_upi_amw_cashierpage_upi_title}
                                </p>
                                <p className="info">
                                    {langStrings.webview_upi_amw_cashierpage_upi_subtitle}
                                </p>
                                <div className="apps-list-container">
                                    <img src="/assets/images/upi-intent.svg" />
                                    <p className="more-text">
                                        {langStrings.webview_upi_am_cashierpage_upi_misc}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    {!displayUPIInput && isUpiCollectValid && (
                        <div onClick={showUPIInput} className="add-upi">
                            <img src="/assets/images/add.svg" alt="" />{' '}
                            <div class="text"> {langStrings.webview_upicollectbox}</div>
                        </div>
                    )}
                </div>
            )}
            {!isMobileDevice && (
                <div className="qr">
                    <div className="qr-code">
                        <div className="left">
                            <div className="title">{langStrings.webview_desktopupiqrheader}</div>
                            <div className="info">{langStrings.webview_desktopupiqrcontent}</div>
                            <div className="apps">
                                <img src="/assets/images/upi-apps.svg" alt="" />
                                <p>{langStrings.webview_more}</p>
                            </div>
                        </div>
                        <div className="right">
                            <div onClick={enlargeQrCode} id="qr-img"></div>
                            {isQRLoaded && <p>{langStrings.webview_tap_to_enlarge}</p>}
                        </div>
                    </div>
                    <div className="divider">
                        <div className="verticle-line"></div>
                        <div className="text">
                            <p>{langStrings.or}</p>
                        </div>
                    </div>
                </div>
            )}
            {displayUPIInput && (
                <div className="add-upi-form">
                    <input
                        placeholder={langStrings.webview_upicollectbox}
                        type="text"
                        onInput={(e) => {
                            // @ts-ignore
                            const input = e.target.value;
                            setUpiId(input);
                            verifyUpiId(input);
                        }}
                        value={upiId}
                    />
                    <button disabled={isDisabled} onClick={() => props.makeUpiPayment(upiId)}>
                        {langStrings.webview_pay}
                    </button>
                </div>
            )}
        </div>
    );
};
