import { i18nKeyValue } from './strings/types';
import { english } from './strings/english';
// import { hinglish } from "./strings/hinglish";
// import { hindi } from "./strings/hindi";
// import { marathi } from "./strings/marathi"
// import { telugu } from "./strings/telugu";
// import { bengali } from "./strings/bengali";
// import { kannada } from "./strings/kannada";
// import { punjabi } from "./strings/punjabi";
// import { gujarati } from "./strings/gujarati";
// import { tamil } from "./strings/tamil";
// import { malayalam } from "./strings/malayalam";
// import { assamese } from "./strings/assamese";
// import { odia } from "./strings/odia";
import { supportedLangsType } from './types';

interface LanguageMapper {
    [key: string]: /* type of supportedLangsType */ i18nKeyValue;
}

export const languageNamesMapper = {
    en: 'English',
    en_US: 'Hinglish',
    hi: 'हिन्दी',
    mr: 'मराठी',
    te: 'తెలుగు',
    bn: 'বাংলা',
    kn: 'ಕನ್ನಡ',
    pa: 'ਪੰਜਾਬੀ',
    gu: 'ગુજરાતી',
    ta: 'தமிழ்',
    ml: 'മലയാളം',
    as: 'অসমীয়া',
    or: 'ଓଡ଼ିଆ',
};

export const languageStringsMapper = async (newLang: supportedLangsType): Promise<i18nKeyValue> => {
    switch (newLang) {
        case 'en':
            return english;
        case 'mr':
            return (await import('./strings/marathi')).marathi;
        case 'en_US':
            return (await import('./strings/hinglish')).hinglish;
        case 'hi':
            return (await import('./strings/hindi')).hindi;
        case 'te':
            return (await import('./strings/telugu')).telugu;
        case 'bn':
            return (await import('./strings/bengali')).bengali;
        case 'kn':
            return (await import('./strings/kannada')).kannada;
        case 'pa':
            return (await import('./strings/punjabi')).punjabi;
        case 'gu':
            return (await import('./strings/gujarati')).gujarati;
        case 'ta':
            return (await import('./strings/tamil')).tamil;
        case 'ml':
            return (await import('./strings/malayalam')).malayalam;
        case 'as':
            return (await import('./strings/assamese')).assamese;
        case 'or':
            return (await import('./strings/odia')).odia;
        default:
            return english;
    }
};

export const defaultLanguage = english;

export const languageNumberToSymbolMapper: { [key: string]: string } = {
    '-1': 'en',
    '1': 'en',
    '2': 'hi',
    '3': 'en_US',
    '4': 'mr',
    '5': 'gu',
    '6': 'pa',
    '7': 'ta',
    '8': 'te',
    '9': 'kn',
    '10': 'bn',
    '11': 'ml',
    '12': 'en',
    '13': 'or',
    '14': 'as',
};
