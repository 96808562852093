declare global {
    interface Window {
        kbDevUtils: {
            log: Console['log'];
            alert(data?: any): void;
            isToastEnabled?: boolean;
            popStateFirstListener?: Function;
            // reduxState:
        };
    }
}

export const initKbDevUtils = (isDev: boolean, isProd: boolean) => {
    if (isDev) {
        window.kbDevUtils = {
            log: (data: any) => window.console.log(data),
            alert: (data: any) => window.alert(data),
            isToastEnabled: true,
        };
    }
    if (isProd) {
        window.kbDevUtils = {
            log: (data: any) => data, // send this data to logging service
            alert: (data: any) => data, // send this data to logging service
            isToastEnabled: false,
        };
    }
};
