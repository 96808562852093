import { i18nKeyValue } from './types';
export const english: i18nKeyValue = {
    webview_header: 'Payment',
    webview_amount: 'Amount',
    webview_paymode: 'Payment mode',
    webview_upi: 'UPI',
    webview_free: 'Free',
    webview_upicollect_cta: 'Pay via UPI ID',
    webview_upicollectbox: 'Enter UPI ID',
    webview_upimoreapps: 'More Apps',
    webview_upiwaitscreen: 'Please accept the collect request sent to your UPI app',
    webview_upiwaitscreencontent2: 'Please approve the payment before the timeout',
    webview_upiwaitscreencontent3: 'Please retry if payment fails',
    webview_pay: 'Pay',
    webview_card: 'Credit / Debit Cards',
    webview_wallet: 'Wallet',
    webview_cardnumber: 'CARD NUMBER',
    webview_cardname: 'NAME ON CARD',
    webview_cardcvv: 'CVV',
    webview_cardexpiry: 'MM/YY',
    webview_cardnumbererror: 'Please enter a valid card number',
    webview_cardcvverror: 'Your card has expired. Please try another card',
    webview_surchargemsg: 'A service fee of {$PaymentAmount} will be charged for this payment.',
    webview_phonepe: 'PhonePe',
    webview_mobikwik: 'MobiKwik',
    webview_airtelpaymentsbank: 'Airtel Payments Bank',
    webview_freecharge: 'Freecharge',
    webview_paymentlimitexceededmsg:
        'Daily limit exhausted. Please use UPI or contact support to increase your payment limit.',
    webview_goback: 'Back',
    webview_footer: 'All payments are safe and secured by Khatabook',
    webview_desktopsurchargemsg: '{$surcharge%} service fee will be charged.',
    webview_desktoppaycta: 'Pay {$PaymentAmount}',
    webview_desktopupiqrheader: 'Scan QR code',
    webview_desktopupiqrcontent:
        'Simply scan the QR code displayed here to make payment from any UPI app.',
    webview_more: '& More',
    webview_tap_to_enlarge: 'Tap to enlarge',
    webview_desktopupicollectwaitingheader: 'Complete UPI Payment',
    webview_enteredupiaddress: 'Entered UPI Address:',
    invalid_cvv: 'Invalid CVV',
    invalid_month: 'Invalid Month',
    invalid_year: 'Invalid Year',
    no_internet_connection: 'No Internet Connection! Try Again',
    sorry_for_inconvenience: 'Sorry for the inconvenience',
    contact_us: 'Contact Us',
    no_internet_connection_tryagain: 'No Internet Connection! Try Again',
    processing_payment: 'Processing your payment!',
    please_wait: 'Please wait...',
    or: 'OR',
    webview_pd_failed: 'Payment Failed',
    webview_pd_failed_msg:
        'Any amount debited from your bank account will be refunded within 7-10 working days',
    webview_noupiappsmsg: 'UPI app not found.',
    webview_cardholdernameerror: "Enter card holder's name",
    try_again: 'Try Again!',
    webview_noupiappsmsg2: 'Please install any BHIM UPI app to complete the payment.',
    invalid_expiry: 'Expired card or incorrect expiry date entered',
    payment_successful: 'Payment Successful!',
    webview_paymentprocessing_header: 'Payment Processing',
    webview_paymentprocessing_content: 'Processing your payment...',
    webview_paymentprocessing_retrymsg: 'Retry if payment not completed.',
    webview_paymentprocessing_retrycta: 'Retry',
    webview_paymentprocessing_refundmsg:
        'If payment fails, any amount debited from bank account will be refunded within 7-10 working days',
    webview_upi_amw_cashierpage_header: 'Payment Modes',
    webview_upi_amw_cashierpage_upi_title: 'Pay via UPI apps',
    webview_upi_amw_cashierpage_upi_subtitle: 'You can pay from other UPI apps on your phone',
    webview_upi_am_cashierpage_upi_misc: '& More',
    paymentssdk_kyc_title: 'Complete KYC',
    paymentssdk_kyc_content:
        'Unlock payment via Credit/Debit cards and Wallets like Freecharge, MobiKwik & others',
    paymentssdk_kyc_cta: 'COMPLETE KYC',
    paymentssdk_limitexceeded:
        'Your approved payment limit is ₹ %(amount)s. Please contact us to increase your payment limits.',
    paymentssdk_supportcta: 'Contact Support',
    credit_card_not_allowed: 'Credit cards not allowed',
    credit_card: 'Credit Cards',
    debit_card: 'Debit Cards'

};
