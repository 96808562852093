import { AnalyticsEventType, AnalyticsEventName } from './event.types';
import { useLogEvent } from './event.setup';
import { BaseError, OrderStatus } from '../services/payments';
import { PaymentMode } from '../helpers/use-payments-helper';

export const useAnalyticsEvents = () => {
    const { eventLogger } = useLogEvent();

    const homePageLoad = () => {
        eventLogger(AnalyticsEventName.HomePageLoad, AnalyticsEventType.PageLoad);
    };

    const homePageLoadError = (e: BaseError) => {
        eventLogger(AnalyticsEventName.HomePageLoadError, AnalyticsEventType.Error, {
            message: e.message,
            code: e.errorCode,
        });
    };

    const completeKYCClick = (params: {
        userId: string;
        feature: string;
        limitBucketId: number;
        velocityBucketId: number;
        KYCStatus: boolean;
        amountSU: number;
    }) => {
        eventLogger(AnalyticsEventName.CompleteKYC, AnalyticsEventType.General, params);
    };

    const makePayment = (params: { mode: PaymentMode; upiMode?: 'collect' | 'intent' }) => {
        eventLogger(AnalyticsEventName.MakePayment, AnalyticsEventType.General, params);
    };

    const makePaymentFailure = (
        params: { mode?: PaymentMode; upiMode?: 'collect' | 'intent' },
        e: BaseError
    ) => {
        eventLogger(AnalyticsEventName.MakePaymentFailure, AnalyticsEventType.Error, {
            message: e.message,
            code: e.errorCode,
            ...params,
        });
    };

    const closeSdk = (params: { status: OrderStatus }) => {
        eventLogger(AnalyticsEventName.CloseSDK, AnalyticsEventType.General, params);
    };

    return {
        homePageLoad,
        homePageLoadError,
        closeSdk,
        makePayment,
        completeKYCClick,
        makePaymentFailure,
    };
};
