import { CommonEventProperties } from './events';
import { AnalyticsEventType } from './event.types';
import { isAnalyticsInit } from './init';

export let _commonEvents: Partial<CommonEventProperties> = {};

export const areCommonEventPropertiesSet = () => Object.keys(_commonEvents).length !== 0;
export const setCommonEventProperties = (
    newCommonEventProperties: Partial<CommonEventProperties>
) => {
    _commonEvents = { ..._commonEvents, ...newCommonEventProperties };
};
export const getCommonEventProperties = () => _commonEvents;

declare global {
    interface Window {
        rudderanalytics: any;
    }
}

export const useLogEvent = () => {
    const eventLogger = (
        eventName: string,
        eventType: AnalyticsEventType,
        eventProperties: object = {},
        suppressEventToast?: boolean
    ) => {
        if (!isAnalyticsInit()) {
            window.kbDevUtils.log('Analytics segment sdk not initialized yet');
            // throw new Error('Cannot log events as analytics is not initialized')
        }
        if (!areCommonEventPropertiesSet()) {
            window.kbDevUtils.log(' common event properties are not set');
            // throw new Error('Cannot log events when common event properties are not set')
        }
        const prefix = 'PaymentsSDK';
        window.rudderanalytics.track(prefix + eventName, {
            ...eventProperties,
            ...getCommonEventProperties(),
            source: 'PaymentsSDK',
            appVersion: '0.01', //process.env.PREACT_APP_VERSION,
            eventType,
        });
    };

    return { eventLogger };
};
