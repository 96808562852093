export enum AnalyticsEventType {
    PageLoad = 'PageLoad',
    Error = 'Error',
    ApiCall = 'ApiCall',
    General = 'General',
}

export enum AnalyticsEventName {
    HomePageLoad = 'HomePageLoad',
    HomePageLoadError = 'HomePageLoadError',
    CloseSDK = 'CloseSDK',
    MakePayment = 'MakePayments',
    MakePaymentFailure = 'MakePaymentFailure',
    CompleteKYC = 'CompleteKYC',
}
