
const getUUID = () => {
    return ([1e7].toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export enum RequestFunction {
    initialiseWebPaymentSdk = 'initialiseWebPaymentSdk',
    paymentIntent = 'paymentIntent',
}

export interface IBrIdgeResponse {
    requestId: string;
    response: any;
}

interface IBridgeRequest {
    requestId: string;
    requestFunction: RequestFunction; //enum
    callbackFunction: string;
    params: any[];
}

interface IBridgeResponse {
    requestId: string;
    response: any;
}

declare global {
    interface Window {
        Android: any;
        payments: any;
    }
}

class AndroidBridge {
    callLog: { [key: string]: Function } = {};
    constructor() {
        if (!window.payments) {
            window.payments = {};
        }
        window.payments.handleCallback = (data: IBridgeResponse) => this.handleCallback(data);
        window.payments.triggerEventFromWeb = (eventName: string, eventProperties: any) => this.triggerEventFromWeb(eventName, eventProperties)
    }
    handleCallback = (data: IBridgeResponse) => {
        console.log(data, 'Response from bridge')
        if (data.requestId && typeof this.callLog[data.requestId] == 'function') {
            this.callLog[data.requestId](data.response);
        } else {
            // console.log(data.requestId);
        }
    };

    callBridge = (requestFunction: RequestFunction, params: any[], callback: Function) => {
        let request: IBridgeRequest = {
            requestId: getUUID(),
            callbackFunction: 'window.payments.handleCallback',
            params: params,
            requestFunction: requestFunction,
        };
        this.callLog[request.requestId] = callback;
        console.log(request)
        window.Android.handleRequest(JSON.stringify(request));
    };

    processQuery = (
        requestFunction: RequestFunction,
        params: any[]
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                this.callBridge(requestFunction, params, (res: any) => {
                    resolve(res);
                });
            } catch (e) {
                reject(e);
            }
        });
    };

    execute = async (
        requestFunction: RequestFunction,
        params: any[],
        //@ts-ignore
        tryCount?: number = 3
    ): Promise<any> => {
        try {
            let res: any = await this.processQuery(requestFunction, params);
            if (res.status !== 'SUCCESS' && tryCount > 1) {
                // await Utils.sleep(1000);
                return await this.execute(requestFunction, params, --tryCount);
            }
            return res;
        } catch (e) {
            if (tryCount >= 1) {
                // await Utils.sleep(1000);
                return await this.execute(requestFunction, params, --tryCount);
            } else {
                // captureException(e as Error);
                throw e;
            }
        }
    };

    triggerEventFromWeb=(eventName: string, eventProperties: any)=>{
        if(eventName==='USER_PROPERTIES' && eventProperties.user_id){
            window.rudderanalytics.identify(eventProperties.user_id, eventProperties);
        }
        else{
            // analytics.loanEventFromBridge({eventName:eventName as any, eventProperties})
        }
    }
}

export default new AndroidBridge();
