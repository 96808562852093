import { isDev, isProd } from '../env';
import { initKbDevUtils } from './kb-dev-utils';
// import { disableReactDevTools } from "./react-devtools-disable";
// import "./modernizr.min"

// rename to kbUtils
initKbDevUtils(isDev, isProd);

// if (isDev) { }

// if (isProd) {
//   // disableReactDevTools()
// }
