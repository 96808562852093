import {CardType, DestinationFeature, PaymentService} from '../../services/payments';

export interface ICardDetails {
    cardNum: string;
    type: CardType;
}

export interface IExpiryValidation {
    isInvalid: boolean;
    isExpired: boolean;
}
interface ICardHelper {
    getCardNumDetails: Function;
    validateCardNum: Function;
    formatExpiry: Function;
    cleanCardNum: Function;
    validateExpiry: Function;
    validateCardholderName: Function;
    validateEnteredCardForCC: Function;
}

export const useCardHelper = (): ICardHelper => {
    const cleanCardNum = (ccNumString: string) => {
        return ccNumString.replace(/[^0-9]/g, '');
    };

    function getCardNumDetails(ccNumString: string): ICardDetails {
        ccNumString = ccNumString.replace(/[^0-9]/g, '');
        var typeCheckStr = ccNumString.substring(0, 2);
        var cType: CardType = GetCardType(ccNumString);
        var block1 = '';
        var block2 = '';
        var block3 = '';
        var block4 = '';
        var formatted = '';

        block1 = ccNumString.substring(0, 4);
        if (block1.length == 4) {
            block1 = block1 + ' ';
        }

        if (cType == CardType.AMERICAN_EXPRESS) {
            block2 = ccNumString.substring(4, 10);
            if (block2.length == 6) {
                block2 = block2 + ' ';
            }
            block3 = ccNumString.substring(10, 16);
            block4 = '';
        } else {
            block2 = ccNumString.substring(4, 8);
            if (block2.length == 4) {
                block2 = block2 + ' ';
            }
            block3 = ccNumString.substring(8, 12);
            if (block3.length == 4) {
                block3 = block3 + ' ';
            }
            block4 = ccNumString.substring(12, 16);
        }

        formatted = block1 + block2 + block3 + block4;

        return { cardNum: formatted, type: GetCardType(ccNumString) };
    }
    function GetCardType(cardNum: string): CardType {
        let payCardType: CardType = CardType.VISA;
        if (cardNum.split('')[0] == '3') {
            payCardType = CardType.AMERICAN_EXPRESS;
        }
        return payCardType;
    }

    const validateCardNum = (cardNo: string) => {
        let cardNumber = cardNo.replace(/[^0-9]/g, '');

        if (cardNumber.length >= 12 && cardNumber.length <= 16) {
            return true;
        } else {
            return false;
        }
    };

    const validateCardholderName = (name: string) => {
        let regexp = /[a-zA-Z]*/;

        if (regexp.test(name)) {
            return true;
        } else {
            return false;
        }
    };

    const validateExpiry = (expiry: string): IExpiryValidation => {
        let expiryValidation: IExpiryValidation = {
            isExpired: false,
            isInvalid: false,
        };
        let pattern = /\b(0[1-9]|1[0-2])\/?[0-9]{2}\b/;
        if (expiry.length !== 5 || !pattern.test(expiry)) {
            expiryValidation.isInvalid = true;
            return expiryValidation;
        }
        let [month, year] = expiry.split('/');
        year = '20' + year;
        let cardExpiry = new Date();
        cardExpiry.setFullYear(parseInt(year), parseInt(month), 0);
        let currDate = new Date();
        expiryValidation.isExpired = !(currDate < cardExpiry);
        return expiryValidation;
    };

    const formatExpiry = (expiry: string) => {
        if (expiry.length == 1) {
            if (parseInt(expiry) > 1) {
                expiry = `0${expiry}`;
            }
        }
        if (expiry.length == 2) {
            expiry += '/';
        }
        return expiry;
    };

    const validateEnteredCardForCC= async({cardNumber, tenant}: {cardNumber: string; tenant: DestinationFeature}): Promise<{success: boolean; isCreditCard?: boolean;}>=> {
        try {
            const result = await new PaymentService().getCardType({cardNumber, feature: tenant})
            if(result.type){
                return {success: true, isCreditCard: result.type === 'credit'}
            }else{
                throw new Error(result?.message)
            }
        }catch (e) {
            throw e
        }
    }

    return {
        getCardNumDetails,
        validateCardNum,
        formatExpiry,
        cleanCardNum,
        validateExpiry,
        validateCardholderName,
        validateEnteredCardForCC
    };
};
