import { FunctionalComponent, h, Fragment } from 'preact';
import { useUpiInputHelper } from '../../helpers/use-upi-input-helper';
import { IPaymentsHelper } from '../../helpers/use-payments-helper';
import { ICreatePgOrderResponse, IUpiIntent } from '../../services/payments';
import { default as QRCode } from 'qrcode-svg';
import { useEffect } from 'preact/hooks';

type Props = {
    closeQRImage: Function;
    upiIntentOrder: ICreatePgOrderResponse | null;
};

export const QREnlarge: FunctionalComponent<Props> = (props: Props) => {
    useEffect(() => {
        const upiIntent = props.upiIntentOrder?.intents.find((intent) => intent.type === 'upi') as
            | IUpiIntent
            | undefined;
        var container = document.getElementById('qr-img-enlarge');
        if (upiIntent && upiIntent.url && container) {
            var svg = new QRCode({
                content: upiIntent.url,
                padding: 4,
                width: 400,
                height: 400,
                color: '#000000',
                background: '#ffffff',
                ecl: 'M',
            }).svg();
            container!.innerHTML = svg;
        }
        document.addEventListener('keydown', (event) => {
            if (event.key == 'Escape') {
                closeQRImage();
            }
        });
    });
    const closeQRImage = () => {
        props.closeQRImage();
    };
    return (
        <div className="qr-enlarge">
            <div className="page-content">
                <button onClick={closeQRImage}>
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="15.9995" cy="15.9995" r="13.8667" fill="white" />
                        <path
                            d="M16 0C7.152 0 0 7.152 0 16C0 24.848 7.152 32 16 32C24.848 32 32 24.848 32 16C32 7.152 24.848 0 16 0ZM22.864 20.624C23.0121 20.7721 23.1296 20.948 23.2098 21.1415C23.29 21.3351 23.3312 21.5425 23.3312 21.752C23.3312 21.9615 23.29 22.1689 23.2098 22.3625C23.1296 22.556 23.0121 22.7319 22.864 22.88C22.7159 23.0281 22.54 23.1456 22.3465 23.2258C22.1529 23.306 21.9455 23.3472 21.736 23.3472C21.5265 23.3472 21.3191 23.306 21.1255 23.2258C20.932 23.1456 20.7561 23.0281 20.608 22.88L16 18.256L11.376 22.88C11.0768 23.1792 10.6711 23.3472 10.248 23.3472C9.82492 23.3472 9.41916 23.1792 9.12 22.88C8.82084 22.5808 8.65277 22.1751 8.65277 21.752C8.65277 21.5425 8.69403 21.3351 8.7742 21.1415C8.85437 20.948 8.97187 20.7721 9.12 20.624L13.744 16L9.136 11.376C8.98787 11.2279 8.87037 11.052 8.7902 10.8585C8.71003 10.6649 8.66877 10.4575 8.66877 10.248C8.66877 9.82492 8.83684 9.41916 9.136 9.12C9.43517 8.82084 9.84092 8.65277 10.264 8.65277C10.6871 8.65277 11.0928 8.82084 11.392 9.12L16 13.744L20.624 9.12C20.7721 8.97187 20.948 8.85436 21.1415 8.7742C21.3351 8.69403 21.5425 8.65277 21.752 8.65277C21.9615 8.65277 22.1689 8.69403 22.3625 8.7742C22.556 8.85436 22.7319 8.97187 22.88 9.12C23.0281 9.26813 23.1456 9.44399 23.2258 9.63753C23.306 9.83107 23.3472 10.0385 23.3472 10.248C23.3472 10.4575 23.306 10.6649 23.2258 10.8585C23.1456 11.052 23.0281 11.2279 22.88 11.376L18.256 16L22.864 20.624Z"
                            fill="#909192"
                        />
                    </svg>
                </button>
                <div id="qr-img-enlarge"></div>
            </div>
        </div>
    );
};
