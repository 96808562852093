import { appName, appVersion, isDev } from '../env';

declare global {
    interface Window {
        rudderanalytics: any;
        Razorpay: any;
    }
}
export interface AnalyticsConfig {
    isDevelopmentMode: boolean;
    appVersion: string;
    appName: string;
}

let _isAnalyticsInit = false;

export const initAnalytics = () => {
    _isAnalyticsInit = true;
};

export const isAnalyticsInit = () => _isAnalyticsInit;

// set page properties when segement is loaded
window?.rudderanalytics?.ready(initAnalytics);
