import { PlatformType } from '../analytics/events';

export enum Environment {
    DEV = 'development',
    PRODUCTION = 'production',
}

export class Utils {
    static getEnvironment() {
        console.log(process.env.NODE_ENV);
        return (process.env.NODE_ENV as Environment) || Environment.DEV;
    }

    static isDev() {
        return this.getEnvironment() === Environment.DEV;
    }

    static delay(ms: number) {
        return new Promise((r) => setTimeout(r, ms));
    }

    static getPlatform(): PlatformType {
        if (window.Android) return 'android';
        if (window.webkit?.messageHandlers) return 'ios';
        return 'web';
    }

    static getSearchParamValue = (searchParam: string) => {
        const urlSearchParams = window.location.search;
        const searchParamsObj = new URLSearchParams(urlSearchParams);
        return searchParamsObj.get(searchParam);
    }

    static clearSearchParamFromURL = (searchParam: string) => {
        const url = window.location.href
        let paramValue = Utils.getSearchParamValue(searchParam)
        if (paramValue) {
            paramValue= encodeURIComponent(paramValue)
            let startIndex = url.indexOf(searchParam)
            const isFollowingIndex = startIndex - 1 >= 0 && url[startIndex - 1] === '&'
            if (isFollowingIndex) {
                startIndex = startIndex - 1
            }
            const endIndex = url.indexOf(paramValue) + paramValue.length
            const updatedUrl = url.slice(0, startIndex) + url.slice(endIndex)
            window.history.replaceState(null, '', updatedUrl);
        }
    }
}
