import { supportedLangsType } from '../i18n/types';
import Constants from '../utils/constants';

export interface BaseError {
    message: string;
    errorCode: string;
}

export enum Tenant {
    MY_STORE = 'my',
    PAGAR_KHATA = 'pk',
    KHATA_BOOK = 'kb',
    IVR = 'ivr',
    B2B = 'b2b',
    PAYMENTS = 'py',
    KB_INVEST = 'iv',
    LOAN_KHATA = 'lk',
    BIZ_A_COINS = 'bs'
}


export enum DestinationFeature {
    PAGAR_KHATA = 'PAGAR_KHATA',
    MY_STORE = 'MY_STORE',
    KHATA_BOOK = 'KHATA_BOOK',
    PAYMENTS = 'PAYMENTS',
    LOAN_KHATA = 'LOAN_KHATA',
    KB_INVEST = 'KB_INVEST',
    IVR = 'ivr',
    B2B = 'b2b',
    BIZ_A_COINS = 'BIZ_A_COINS'
}

export const TenantMap: { [key in Tenant]: DestinationFeature } = {
    my: DestinationFeature.MY_STORE,
    pk: DestinationFeature.PAGAR_KHATA,
    kb: DestinationFeature.KHATA_BOOK,
    py: DestinationFeature.PAYMENTS,
    lk: DestinationFeature.LOAN_KHATA,
    iv: DestinationFeature.KB_INVEST,
    ivr: DestinationFeature.IVR,
    b2b: DestinationFeature.B2B,
    bs: DestinationFeature.BIZ_A_COINS

};

export enum CardType {
    AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
    RUPAY = 'RUPAY',
    MASTERCARD = 'MASTERCARD',
    VISA = 'VISA',
    INVALID = 'INVALID',
}

export enum OrderStatus {
    PENDING = 'PENDING',
    FAILURE = 'FAILURE',
    EXPIRED = 'EXPIRED',
    SUCCESS = 'SUCCESS',
    CANCELLED = 'CANCELLED',
}

export enum PaymentOptions {
    UPI = 'UPI',
    CC = 'CC',
    DC = 'DC',
    WALLET = 'WALLET',
    UPI_INTENT = 'UPI_INTENT',
    NET_BANKING = 'NET_BANKING'
}

export interface PaymentModeLimit {
    limit: number;
    editable: number;
    kyc_limit: number;
}

export interface Limits {
    CC: PaymentModeLimit;
    DC: PaymentModeLimit;
    UPI: PaymentModeLimit;
}

export interface NetBankingItem {
    bankName: string;
    bankCode: string;
}

export type MdrCharges = { [key in keyof typeof PaymentOptions]: IMdrCharge };
export interface IOrderDetails {
    status: OrderStatus;
    kbInvoiceId: string;
    callbackUrl: string;
    kbOrderId: string;
    isKYCDone: boolean;
    amount: number;
    currency: 'INR';
    lang: supportedLangsType;
    limitBucketId: number;
    velocityBucketId: number;
    paymentOptions: Array<keyof typeof PaymentOptions>;
    mdrCharges: MdrCharges;
    limits: Limits;
    surcharge: boolean;
    payer: {
        userId: string;
        phone: string;
    };
}

interface IMdrCharge {
    mdr: number;
    types?: { [key in string]: { mdr: number } };
}

export interface IBaseError {
    message: string;
    errorCode: string;
}

export interface IFetchPaymentDetailDto {
    shortLink: string;
}

export interface IPgIntent {
    type: 'pg';
    appId: string;
    orderId: string;
    callbackUrl: string;
    gateway: string;
}

export type SupportedUpiApps = 'googlepay' | 'paytm' | 'phonepe';

export interface IUpiIntent {
    type: 'upi';
    url: string;
    appUrls: Array<{ appName: SupportedUpiApps; link: string }>;
    gateway: string;
}

export interface ICreatePgOrderDto {
    shortLink: string;
    paymentMode: PaymentOptions;
    paymentModeType?: CardType;
    netBankingSelectedBank?: string;
}

export interface ICreatePgOrderResponse {
    intents: Array<IPgIntent | IUpiIntent>;
    mdrCharges?: MdrCharges;
    amountSU: number;

}

/*{
    "status": "PENDING",
    "kbInvoiceId": "3cf75107-789c-4494-8fab-0df40f208256",
    "kbOrderId": "bb91111a-25f3-476d-b456-aa1ea8e24ab0",
    "amount": 100,
    "currency": "INR",
    "createdAt": 1726128717912,
    "surcharge": false,
    "mdrCharges": {
    "UPI": {
        "mdr": 0
    },
    "UPI_INTENT": {
        "mdr": 0
    }
},
    "paymentOptions": [
    "UPI",
    "UPI_INTENT"
],
    "payer": {
    "phone": "9549911359",
        "userId": "baa57ab7-c2f7-4b0d-8b92-72ab79a2906e"
},
    "isKYCDone": false,
    "lang": "en",
    "limitBucketId": "1",
    "velocityBucketId": "4",
    "callbackUrl": "http://localhost:8080/?short_id=92D2i0K8fc&utm_source=SUPPORT_PANEL&"
}*/
const response={
    status: "PENDING",
    kbInvoiceId: "3cf75107-789c-4494-8fab-0df40f208256",
    kbOrderId: "bb91111a-25f3-476d-b456-aa1ea8e24ab0",
    amount: 100,
    currency: "INR",
    createdAt: 1726128717912,
    surcharge: false,
    mdrCharges: {
        UPI: {
            mdr: 0
        },
        UPI_INTENT: {
            mdr: 0
        }
    },
    paymentOptions: [
        "UPI",
        "UPI_INTENT"
    ],
    payer: {
        phone: "9549911359",
        userId: "baa57ab7-c2f7-4b0d-8b92-72ab79a2906e"
    },
    isKYCDone: false,
    lang: "en",
    limitBucketId: "1",
    velocityBucketId: "4",
    callbackUrl: "http://localhost:8080/?short_id=92D2i0K8fc&utm_source=SUPPORT_PANEL&"
}

const gatewayOrderResponse={
    amountSU: 2400,
    currency: "INR",
    intents: [
        {
            type: "upi",
            url: "upi://pay?pa=upi@razopay&pn=khatabook&tr=binY8rEojafW3M7&tn=razorpay&am=24&cu=INR&mc=5411",
            appUrls: [
                {
                    appName: "googlepay",
                    link: "tez://upi/pay?pa=upi@razopay&pn=khatabook&tr=binY8rEojafW3M7&tn=razorpay&am=24&cu=INR&mc=5411"
                },
                {
                    appName: "paytm",
                    link: "paytm://upi/pay?pa=upi@razopay&pn=khatabook&tr=binY8rEojafW3M7&tn=razorpay&am=24&cu=INR&mc=5411"
                },
                {
                    appName: "phonepe",
                    link: "phonepe://upi/pay?pa=upi@razopay&pn=khatabook&tr=binY8rEojafW3M7&tn=razorpay&am=24&cu=INR&mc=5411"
                }
            ],
            gateway: "razorpay"
        }
    ],
    mdrCharges: {
        UPI: {
            mdr: 0
        },
        UPI_INTENT: {
            mdr: 0
        }
    }
}
export class PaymentService {
    constructor() {}

    async fetchShortLinkDetails(params: IFetchPaymentDetailDto): Promise<IOrderDetails> {
        const result = await fetch(
            `${Constants.paymentsShortLinkUrl}?shortLink=${params.shortLink}`
        ).then((res) => res.json());
        return result as IOrderDetails;
    }

    async createPgOrder(params: ICreatePgOrderDto): Promise<ICreatePgOrderResponse> {
        const result = await fetch(`${Constants.createPgOrderUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
             ...params
            }),
        }).then((res) => res.json());

        return result as ICreatePgOrderResponse;
    }

    getCardType= async({cardNumber, feature}: {cardNumber: string;feature: string}): Promise<{type: string; [key: string]: any}> => {
        const result = await fetch(`${Constants.fetchCardType}?${  new URLSearchParams({
            cardNumber,
            feature
})}`, {method: 'GET'})
        return await result.json()
    }

    getNetBankingOptions = async ({feature}: { feature: string }) => {
        const result = await fetch(`${Constants.fetchNetBankingOptions}/?${new URLSearchParams({
            feature
        })}`, {method: 'GET'})
        const jsonResponse = await result.json()
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const netBankingObject = jsonResponse?.netBanking as any
        const netBankingKeys = Object.keys(netBankingObject)
        const netBankingList = []
        for (let i = 0; i < netBankingKeys.length; i++) {
            const bankCode = netBankingKeys[i]
            netBankingList.push({
                bankCode,
                bankName: netBankingObject[bankCode]
            })
        }
        return netBankingList
    }

    syncUsedPspApp = async ({mobileNumber, usedPspApp}: { mobileNumber: string; usedPspApp: {packageName: string; appName: string} }) => {

        const result = await fetch(`${Constants.syncPspUsedApp}/${mobileNumber}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                usedPspApp
            })
        })

    }
}

/*
{
    "order": {
        "status": "PENDING",
        "kbInvoiceId": "905af660-a38e-4e7b-8b5d-30c4846c047a",
        "kbOrderId": "58997152-ffdc-4399-998d-e1b24095e9ae",
        "amount": 200,
        "currency": "INR",
        "paymentOptions": [
            "CC",
            "DC",
            "UPI",
            "WALLET",
            "UPI_INTENT"
        ],
        "intents": [
            {
                "type": "upi",
                "url": "upi://pay?tr=GptySn4aXWCNv6&am=2.00&cu=INR&mc=5411&pa=razor.pay@sbi&pn=ADJUtilityAppsPvtLtd&tn=Kyte",
                "appUrls": [
                    {
                        "appName": "googlepay",
                        "link": "tez://upi/pay?tr=GptySn4aXWCNv6&am=2.00&cu=INR&mc=5411&pa=razor.pay@sbi&pn=ADJUtilityAppsPvtLtd&tn=Kyte"
                    },
                    {
                        "appName": "paytm",
                        "link": "paytm://upi/pay?tr=GptySn4aXWCNv6&am=2.00&cu=INR&mc=5411&pa=razor.pay@sbi&pn=ADJUtilityAppsPvtLtd&tn=Kyte"
                    },
                    {
                        "appName": "phonepe",
                        "link": "phonepe://upi/pay?tr=GptySn4aXWCNv6&am=2.00&cu=INR&mc=5411&pa=razor.pay@sbi&pn=ADJUtilityAppsPvtLtd&tn=Kyte"
                    }
                ],
                "gateway": "razorpay"
            },
            {
                "type": "pg",
                "appId": "rzp_live_mgdvPFCcElquQv",
                "orderId": "order_GptyTe1UaMcsUX",
                "callbackUrl": "https://dev.api.khatabook.in/payments/v1/payment-link-webhook/razorpay/return?shortLink=K8UxD4xYE/",
                "gateway": "razorpay"
            }
        ]
    }
}
*/
